import React from "react";
import highq from '../../resources/projects_screen_250/highq.jpg';
import funda from '../../resources/projects_screen_250/funda.jpg';
import cdlog from '../../resources/projects_screen_250/cdlog.jpg';
import cderp from '../../resources/projects_screen_250/cderp.jpg';
import ppc from '../../resources/projects_screen_250/ppc.jpg';
import brandpool from '../../resources/projects_screen_250/brandpool.jpg';
import gamers from '../../resources/projects_screen_250/gamers.jpg';
import tmz from '../../resources/projects_screen_250/tmz.jpg';
import mediamanager from '../../resources/projects_screen_250/mediamanager.jpg';
import nemesysco from '../../resources/projects_screen_250/nemesysco.jpg';
import ofilsystems from '../../resources/projects_screen_250/ofilsystem.jpg';
import egoeast from '../../resources/projects_screen_250/egoeast.jpg';
import sprkl from '../../resources/projects_screen_250/sprkl.jpg';

import highq_530 from '../../resources/projects_screen_530/highq.jpg';
import funda_530 from '../../resources/projects_screen_530/fanda.jpg';
import cdlog_530 from '../../resources/projects_screen_530/cdlog.jpg';
import cderp_530 from '../../resources/projects_screen_530/erp.jpg';
import ppc_530 from '../../resources/projects_screen_530/ppc.jpg';
import brandpool_530 from '../../resources/projects_screen_530/brandpool.jpg';
import gamers_530 from '../../resources/projects_screen_530/gamers.jpg';
import tmz_530 from '../../resources/projects_screen_530/tms.jpg';
import mediamanager_530 from '../../resources/projects_screen_530/mediamanager.jpg';
import nemesysco_530 from '../../resources/projects_screen_530/nemesysco.jpg';
import ofilsystems_530 from '../../resources/projects_screen_530/ofilsystems.jpg';
import egoeast_530 from '../../resources/projects_screen_530/egoeast.jpg';
import sprkl_530 from '../../resources/projects_screen_530/sprkl.jpg';

import java from '../../resources/develop_technologies/java.svg';
import js from '../../resources/develop_technologies/js.svg';
import csharp from '../../resources/develop_technologies/csharp.svg';
import go from '../../resources/develop_technologies/go.svg';
import php from '../../resources/develop_technologies/php.svg';

import cd_log_logo from "../../resources/client_logos/cd_log.svg";
import funda_logo from "../../resources/client_logos/funda.svg";
import ppc_shield_logo from "../../resources/client_logos/ppc_shield.png";
import highq_logo from "../../resources/client_logos/highq.svg";
import brandpool_logo from "../../resources/client_logos/brandpool.png";
import gamers_outlet_logo from "../../resources/client_logos/gamers_outlet.png";
import tms_logo from "../../resources/client_logos/tms.svg";
import erp_logo from "../../resources/client_logos/erp.svg";
import mediamanager_logo from "../../resources/client_logos/mediamanager.png";
import nemesysco_logo from '../../resources/client_logos/nemesysco.svg';
import ofilsystems_logo from '../../resources/client_logos/ofilsystems.png';
import egoeast_logo from '../../resources/client_logos/egoeast.svg';
import sprkl_logo from '../../resources/client_logos/sprkl.png';

const smallProjectList = [
    {
        id: 0,
        screen: highq,
        title: <div className={'title'}>High-Q</div>,
        text: <div className={'text'}>
            <div>LXP/LMS</div>
            <div>System</div>
        </div>,
    },{
        id: 1,
        screen: funda,
        title: <div className={'title'}>Funda.is</div>,
        text: <div className={'text'}>
            <div>Crowd Data Sourcing</div>
            <div>Platform MVP</div>
        </div>,
    },{
        id: 2,
        screen: cdlog,
        title: <div className={'title'}>CD-Log</div>,
        text: <div className={'text'}>
            <div>B2B/B2C</div>
            <div>E-commerce Platform</div>
        </div>,
    },{
        id: 3,
        screen: cderp,
        title: <div className={'title'}>CD-ERP</div>,
        text: <div className={'text'}>
            <div>Enterprise</div>
            <div>Resource Planning</div>
        </div>,
    },{
        id: 4,
        screen: ppc,
        title: <div className={'title'}>PPC Shield</div>,
        text: <div className={'text'}>
            <div>"Clickjacking" Protection</div>
            <div>SaaS Platform</div>
        </div>,
    },{
        id: 5,
        screen: brandpool,
        title: <div className={'title'}>BRANDPOOL</div>,
        text: <div className={'text'}>
            <div>Custom BI SaaS</div>
            <div>Platform</div>
        </div>,
    },{
        id: 6,
        screen: gamers,
        title: <div className={'title'}>GAMERS <span style={{fontSize: '20px'}}>OUTLET</span></div>,
        text: <div className={'text'}>
            <div>E-commerce</div>
            <div>Platform</div>
        </div>,
    },{
        id: 7,
        screen: tmz,
        title: <div className={'title'}>TMS</div>,
        text: <div className={'text'}>
            <div>E-commerce</div>
            <div>Platform</div>
        </div>,
    },{
        id: 8,
        screen: mediamanager,
        title: <div className={'title'} style={{fontSize: '22px'}}>Remote Media Manager</div>,
        text: <div className={'text'}>
            <div>IOS</div>
            <div>Application</div>
        </div>,
    },{
        id: 9,
        screen: nemesysco,
        title: <div className={'title'} style={{fontSize: '22px'}}>Nemesysco</div>,
        text: <div className={'text'}>
            <div>Voice Analysis</div>
            <div>Technologies</div>
        </div>,
    },{
        id: 10,
        screen: ofilsystems,
        title: <div className={'title'} style={{fontSize: '22px'}}>Ofil Systems</div>,
        text: <div className={'text'}>
            <div>Advanced Inspection</div>
            <div>Solutions</div>
        </div>,
    },{
        id: 11,
        screen: sprkl,
        title: <div className={'title'} style={{fontSize: '22px'}}>sprkl</div>,
        text: <div className={'text'}>
            <div>Personal Observability</div>
            <div>Platform</div>
        </div>,
    }, {
        id: 12,
        screen: egoeast,
        title: <div className={'title'} style={{fontSize: '22px'}}>EGOeast</div>,
        text: <div className={'text'}>
            <div>Adversting,</div>
            <div>Productions, PR</div>
        </div>,
    },
];

const projectListForModal = [
    {
        id: 0,
        screen: highq_530,
        technologies: [java, js],
        logo: highq_logo,
        rightLogoText: <>
            <div>LXP/LMS</div>
            <div>System</div>
        </>,
        projectDescription: <>
            <div>Design and development of the most personalized Psychometry Learning and Examination system within a limited time and budget.</div>
            <div>The collaboration with Israeli colleagues and education specialists, and the ability to quickly understand the specific requirements from the Israeli Ministry of Education for the Psychometry preparation and examination process was the most critical part of the HighQ LXP/LMS platform delivery and success!</div>
        </>,
        deliveredToTheClient: <>
            <li>Psychometric simulation and examination.</li>
            <li>Training and Practice.</li>
            <li>Online Group Work - Learning and Teaching Hub.</li>
            <li>Play2Learn - Gamified learning framework.</li>
            <li>Quiz  - Personalized and interactive educational quizzes.</li>
        </>,
    },{
        id: 1,
        screen: funda_530,
        technologies: [php, js],
        logo: funda_logo,
        rightLogoText: <>
            <div>Crowd</div>
            <div>Data Sourcing</div>
            <div>Platform MVP</div>
        </>,
        projectDescription: <>
            <div>Realization of an early-stage startup idea based on the “Crowd data sourcing as an investment “ concept.</div>
            <div>Our specialists were involved in all of the conceptualization, product design, branding, and product development phases.</div>
        </>,
        deliveredToTheClient: <>
            <li>PWA application</li>
            <li>Business analysis, competitive market research.</li>
            <li>Brand conceptualization</li>
            <li>Product conceptualization and design.</li>
            <li>Brand conceptualization</li>
            <li>Product MVP delivery</li>
        </>,
    },{
        id: 2,
        screen: cdlog_530,
        technologies: [php, js, csharp, go],
        logo: cd_log_logo,
        rightLogoText: <>
            <div>B2B/B2C</div>
            <div>E-commerce</div>
            <div>Platform</div>
        </>,
        projectDescription: <>
            <div>B2B, PWA platform development for the largest computer parts and peripherals importer/supplier in Israel.</div>
            <div>The critical key to successful platform delivery was to understand and collaborate with a client various business processes such as supply chain, processes automation, warehouse management, sales and marketing.</div>
            <div>Tahion.pro specialist's expertise, in-depth market understanding, and innovative ideas allowed us to establish solid and long-term relations with a client for many years.</div>
        </>,
        deliveredToTheClient: <>
            <li>B2B distribution portal.</li>
            <li>Stock management system including suppliers and customers management.</li>
            <li>Procurement strategy based predictive pricing mechanism.</li>
        </>,
    },{
        id: 3,
        screen: cderp_530,
        technologies: [php, js, csharp],
        logo: erp_logo,
        rightLogoText: <>
            <div>Enterprise</div>
            <div>Resource</div>
            <div>Planning</div>
        </>,
        projectDescription: <>
            <div>Design and development of a custom and flexible ERP SaaS platform, including processes automation.</div>
            <div>Business and competitive analysis led by our specialists help us to offer and deliver the client with a professional, business-oriented ERP software in a minimum time, budget, and as per exact organization requirements.</div>
        </>,
        deliveredToTheClient: <>
            <li>Custom ERP SaaS platform.</li>
            <li>Procurement and Supply chain management.</li>
            <li>Predictive processes analysis and optimization.</li>
            <li>Documents flow automation, nomenclature management.</li>
        </>,
    },{
        id: 4,
        screen: ppc_530,
        technologies: [php, js],
        logo: ppc_shield_logo,
        rightLogoText: <>
            <div>"Clickjacking"</div>
            <div>Protection</div>
            <div>SaaS Platform</div>
        </>,
        projectDescription: <>
            <div>Design and Development of the "Clickjacking" protection platform for Google Ads advertising organizations and campaign managers. </div>
            <div>PPC Shield is an innovative service that protects advertisers from "erroneous" clicks in Google Ads campaigns. </div>
        </>,
        deliveredToTheClient: <>
            <li>Google Ads campaign real-time monitoring platform.</li>
            <li>"Clickjacking" detection and isolation component.</li>
            <li>"Clickjacking" attack prediction mechanism.</li>
            <li>BI and Data analytics cockpit.</li>
        </>,
    },{
        id: 5,
        screen: brandpool_530,
        technologies: [php, js],
        logo: brandpool_logo,
        rightLogoText: <>
            <div>Custom</div>
            <div>BI SaaS</div>
            <div>Platform</div>
        </>,
        projectDescription: <>
            <div>Design and development of a custom Business Intelligence (BI) SaaS platform that allows the client to aggregate, analyze, simulate, and administer statistical and analytical data from various marketplaces, including data analysis, classification, and clustering.</div>
        </>,
        deliveredToTheClient: <>
            <li>Custom BI SaaS platform as per strict requirements.</li>
            <li>Data aggregation solution design.</li>
            <li>API interfaces design and development.</li>
            <li>Data extraction mechanism.</li>
            <li>Data analysis, extrapolation, and visualization components.</li>
            <li>Data classification and clustering for future usage by Data Scientists.</li>
        </>,
    },{
        id: 6,
        screen: gamers_530,
        technologies: [php, js],
        logo: gamers_outlet_logo,
        rightLogoText: <>
            <div>E-commerce</div>
            <div>Platform</div>
        </>,
        projectDescription: <>
            <div>A user-centric E-commerce platform that allows the client to increase sales and populate "Gamers Culture" Worldwide.</div>
        </>,
        deliveredToTheClient: <>
            <li>Market competitive analysis.</li>
            <li>Product design.</li>
            <li>Complete E-commerce platform.</li>
            <li>Procurement automation solution.</li>
            <li>CRM Component.</li>
            <li>Integration with 3rd party service providers.</li>
        </>,
    },{
        id: 7,
        screen: tmz_530,
        technologies: [php, js],
        logo: tms_logo,
        rightLogoText: <>
            <div>E-commerce</div>
            <div>Platform</div>
        </>,
        projectDescription: <>
            <div>Design and development of a user centric, PWA E-commerce platform for one of the largest computer parts distributors helping the client to enforce users’ experience, increase sales, and populate the organization’s brand.</div>
        </>,
        deliveredToTheClient: <>
            <li>Product design.</li>
            <li>Customized E-commerce platform.</li>
            <li>Behavior-based items configurator component.</li>
            <li>Supply and Delivery management, tracking component.</li>
            <li>Automated clients support component.</li>
            <li>PCI/DSS billing solution.</li>
        </>,
    },{
        id: 8,
        screen: mediamanager_530,
        technologies: [php, js],
        logo: mediamanager_logo,
        rightLogoText: <>
            <div>Application</div>
        </>,
        projectDescription: <>
            <div>Powerful intuitive SMB/Cloud multiformat video player, file browser and viewer for iOS. App can view, copy, move, stream, and manage files between NAS drives, computers, servers, and cloud storage in any direction. App scans and locates devices on your network and connect in seconds.</div>
            <div>App connects to computers with Mac OS, Windows and Linux, work with files stored on Apple Time Capsule, NAS, WebDav, Cloud services (iCloud, OneDrive, Dropbox, Box, Google Drive)</div>
        </>,
        deliveredToTheClient: <>
            <li>Connect to FTP, SFTP, SMB, WebDav, Cloud services</li>
            <li>Wireless transfer files from any network device</li>
            <li>Streaming audio and video to your HDTV via Apple TV and Google Chromecast and from iCloud, OneDrive, Dropbox, Google Drive, Box, Yandex.Disk</li>
            <li>Access to media library on the iOS device (Music, Video, Photos)</li>
            <li>Watch online TV, listen to Internet radio (M3U playlists support)</li>
        </>,
    },{
        id: 9,
        screen: nemesysco_530,
        technologies: [csharp],
        logo: nemesysco_logo,
        rightLogoText: <>
            <div>Voice</div>
            <div>Analysis</div>
            <div>Technologies</div>
        </>,
        projectDescription: <>
            <div>Nemesysco is the leading provider of voice analysis technologies for genuine emotion detection, serving governments and enterprises around the world.</div>
            <div>The technology is based on a proprietary set of vocal parameters found through research to correlate with key human emotions, and in various combinations to be able to identify deceptive intentions in “real life” scenarios. These vocal parameters were identified from a bank of audio files taken in different languages and a numerous settings, including police interrogations, call centers and controlled experiments.</div>
        </>,
        deliveredToTheClient: <>
            <li>Customer and product subscription management system</li>
            <li>Product Interface Update</li>
            <li>Development of win applications for working with streaming audio</li>
        </>,
    },{
        id: 10,
        screen: ofilsystems_530,
        technologies: [csharp],
        logo: ofilsystems_logo,
        rightLogoText: <>
            <div>Advanced</div>
            <div>Inspection</div>
            <div>Solutions</div>
        </>,
        projectDescription: <>
            <div>Ofil Systems, a leading global provider of Ultra-violet Non-Destructive Testing (NDT) technology, providing innovative systems for accurate, reliable, and smart inspection solutions.</div>
            <div>The goal of the project is to develop a pilot version of Ofil’s Oil Spill Cameras, and to deploy it on an oil rig.</div>
            <div>General system design comprises of a 2-senior system for detecting oil slicks on sea surface.</div>
        </>,
        deliveredToTheClient: <>
            <li>UV Camera for detection of slicks as thin as 0.1μm</li>
            <li>IR camera for detection of slick at the range of 30μm and higher</li>
            <li>The cameras will be controlled by a PC in a control room which will also record the findings</li>
            <li>The system will  be installed on an oil rig for a 2-3 month period pilot evaluation</li>
        </>,
    },{
        id: 11,
        screen: sprkl_530,
        technologies: [js],
        logo: sprkl_logo,
        rightLogoText: <>
            <div>Personal</div>
            <div>Observability</div>
            <div>Platform</div>
        </>,
        projectDescription: <>
            <div>"Sprkl" is a Personal Observability platform that provides
                individual developers with telemetry data and actionable insights
                about their code changes. "Sprkl" leverage Open-Telemetry to
                instrument every code change and analyze it upon execution.
            </div>
            <div>
                Immediate response is available directly in the IDE, including
                code-level traces, instrument-level traces (within an embedded
                Jagger instance), diff coverage insights, errors, hidden API calls,
                performance bottlenecks, memory usage anomalies detection,
                and much more!
            </div>
        </>,
        deliveredToTheClient: <>
            <li>Platform interface development</li>
            <li>Platform website advanced visualization</li>
            <li>Dynamic and interactive objects design and development</li>
            <li>FrontEnd {'<>'} BackEnd integration</li>
        </>,
    },
    {
        id: 12,
        screen: egoeast_530,
        technologies: [php, js],
        logo: egoeast_logo,
        rightLogoText: <>
            <div>Adversting</div>
            <div>Productions</div>
            <div>PR</div>
        </>,
        projectDescription: <>
            <div>Digital Advertising agency providing High-End interactive solutions to markets in Israel and globally.</div>
        </>,
        deliveredToTheClient: <>
            <li>WEB 3.0 platform development, including prototyping, design, software development, and integration. </li>
        </>,
    },
];

// @ts-ignore
export {smallProjectList, projectListForModal};