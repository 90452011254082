import React, {useEffect, useState} from 'react';
import './Header.scss';
import logo from '../../resources/logo.svg';
import {Motion, spring} from 'react-motion';
import {usePageScrolling} from '../../hooks/usePageScrolling';
import Hamburger from "./Hamburger/Hamburger";

interface IHeaderProps {
    showSideMenu: boolean,
    setShowSideMenu: Function,
}

function Header(props: IHeaderProps) {
    const defaultTopPos = 10;
    const sizeScrolling = usePageScrolling();
    const [topPos, setTopPos] = useState(sizeScrolling);
    
    useEffect(() => {
        if(sizeScrolling <= defaultTopPos) setTopPos((old) => defaultTopPos - sizeScrolling );
        else setTopPos(0);
    },[sizeScrolling]);
    
    return (
        <Motion defaultStyle={{top: defaultTopPos}} style={{top: spring(topPos)}}>
            {value => {
                const opacity = 0.75 - (value.top/defaultTopPos);
                const blur = (defaultTopPos - value.top)/defaultTopPos*100;
                const shadow = (defaultTopPos - value.top)/defaultTopPos*0.15;
                return (
                    <>
                        <div className="Header" style={{
                            top: value.top, 
                            backgroundColor: 'rgba(255, 255, 255, '+opacity+')',
                            backdropFilter: 'blur('+blur+'px)',
                            boxShadow: '0px 15px 30px rgba(32, 9, 81, '+shadow+')'
                        }}>
                            <div className="content">
                                <div className={'logo'}>
                                    <div><img className={'logo-img'} src={logo}  alt={'logo'}/></div>
                                    <div className={'logo-text'}>Your Trusted Software Development Partner</div>
                                </div>
                                <div>
                                    <div style={{height: "37.5px", width: '36px'}} />
                                </div>
                            </div>
                        </div>
                        <div className="Header" style={{
                            top: value.top,
                            zIndex: 20,
                        }}>
                            <div className="content">
                                <a href={'#turn-key-services'} className={'logo'} style={{height: "50px", width: '289px'}}/>
                                <div>
                                    <Hamburger showSideMenu={props.showSideMenu} setShowSideMenu={props.setShowSideMenu}/>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            }
        </Motion>
    );
}

export default Header;
