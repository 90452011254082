import React from 'react';
import './SideMenuFooter.scss'
import whatsapp from './whatsapp.svg'
import telegram from './telegram.svg'
import mail from './mail.svg'
import linked from './linked_in.svg'
import belarus from './belarus.svg'
import israel from './israel.svg'
import india from './india.svg'
import EmLogo from  "../../../resources/em-logo-horizontal.svg";

function CountryTitle(props: {icon: any, name: string}){
    return (
        <div className={'title'}>
            <span className={'icon-container'}><img src={props.icon} alt={props.name} /></span>
            {props.name}
        </div>
    );
}

function ContactInfo(props: {footer: "main"|"side-menu", className: string, title: any, address: any, phone: any, email: any}){
    switch (props.footer){
        case "side-menu":
            return (
                <div className={'contact-info side-menu '+props.className}>
                    {props.title}
                    <div className={'info'}>
                        {props.phone}
                        {props.email}
                    </div>
                </div>
            );
        case "main":
        default:
            return (
                <div className={'contact-info '+props.className}>
                    {props.title}
                    <div className={'info'}>
                        {props.address}
                        {props.phone}
                        {props.email}
                    </div>
                </div>
            );
    }
}
    

interface SideMenuFooterProps {
    footer: "main"|"side-menu";
}
function SideMenuFooter(props: SideMenuFooterProps) {
    return (
        <div className={'SideMenuFooter'}>
            <div className={'addresses '+props.footer}>
                <ContactInfo 
                    footer={props.footer}
                    className={'belarus'}
                    title={<CountryTitle icon={belarus} name={'Belarus'} />}
                    address={<div className={'address'}>
                        <div className={'no-wrap'}>Pobedy Avenue 7,&nbsp;</div>
                        <div className={'no-wrap'}>Vitebsk</div>
                    </div>}
                    phone={<div className={'phone'}><a href="tel:+375 (33) 308-13-13">+375 (33) 308-13-13</a></div>}
                    email={<div className={'email'}><a href="mailto:vitayu@tahion.pro">vitayu@tahion.pro</a></div>}
                />
                <ContactInfo
                    footer={props.footer}
                    className={'israel'}
                    title={<CountryTitle icon={israel} name={'Israel'} />}
                    address={<div className={'address'}>
                        <div className={'no-wrap'}>Raoul Wallenberg 14,&nbsp;</div>
                        <div className={'no-wrap'}>Tel Aviv-Yafo</div>
                    </div>}
                    phone={<div className={'phone'}><a href="tel:+972 (3) 374-10-24">+972 (<span style={{
                        fontSize: '1px',
                        visibility: 'hidden',
                        opacity: 0
                    }}>.</span>3) 374-10-24</a></div>}
                    email={<div className={'email'}><a href="mailto:shalom@tahion.pro">shalom@tahion.pro</a></div>}
                />
       {/*         <ContactInfo
                    footer={props.footer}
                    className={'india'}
                    title={<CountryTitle icon={india} name={'India'} />}
                    address={<div className={'address'}>
                        <div className={'no-wrap'}>9/A, Neetanand,&nbsp;</div>
                        <div className={'no-wrap'}>SB Road, Pune, Maharashtra</div>
                    </div>}
                    phone={<div className={'phone'}><a href="tel:+918 (36) 985-19-00">+918 (36) 985-19-00</a></div>}
                    email={<div className={'email'}><a href="mailto:namaste@tahion.pro">namaste@tahion.pro</a></div>}
                />*/}
            </div>
            <div className={'contacts'}>
                <span className={'item'}>
                    <a target="_blank"
                       rel="noreferrer" 
                       href="https://wa.me/972546655677?text=Hello, Dan!" className={'icon'}
                    ><img src={whatsapp} alt={'whatsapp'} /></a>
                </span>
                <span className={'item'}>
                    <a target="_blank"
                       rel="noreferrer" 
                       href="https://telegram.me/The7thsky2021" className={'icon'}
                    ><img src={telegram} alt={'telegram'} /></a>
                </span>
                <span className={'item'}>
                    <a href="mailto:hello@tahion.pro" className={'icon'}><img src={mail} alt={'mail'} /></a>
                </span>
                <span className={'item'}>
                    <a target="_blank"
                       rel="noreferrer" 
                       href={"https://www.linkedin.com/company/tahion-pro"} 
                       className={'icon'}
                    ><img src={linked} alt={'linked'} /></a>
                </span>
            </div>
            <div className={'general-email'}>
                hello@tahion.pro
            </div>
            <a href="https://emotionlogic.ai/partner-with-us/" className={'em-logo'} target={'_blank'}>
                <img src={EmLogo} alt={'Emotion Login Trusted Development Partner'} />
            </a>
        </div>
    );
}

export default SideMenuFooter;
