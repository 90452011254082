import React, {useState} from 'react';
import './IndustriesList.scss';
import e_commerce from "./e_commerce.svg";
import wholesale from "./wholesale.svg";
import education from "./education.svg";
import ad from "./ad.svg";
import mobile_app from "./mobile_app.svg";
import IndustriesItem from "./IndustriesItem/IndustriesItem";
import IndustriesModal from "./ModalComponent/IndustriesModal";
 
function IndustriesList() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [currentInd, setCurrentInd] = useState<any>(null);
    const [listInds] = useState(() => [
        {
            id: 1,
            icon: e_commerce,
            title: 'E-commerce and Financial',
            shortText: 'We\'ve designed and deployed innovative, custom-built technology systems for e-commerce and financial entities...',
            text: 'We\'ve designed and deployed innovative, custom-built technology systems for e-commerce and financial entities. Products built by Tahion.pro for its customers consistently meet the highest market, security, and quality standards, and enable compliance with all applicable regulations and laws.',
        },{
            id: 2,
            icon: wholesale,
            title: 'Wholesale and Retail',
            shortText: 'CRM systems we have designed and support function as a comprehensive solution for wholesale and retail providers — enabling systematic...',
            text: 'CRM systems we have designed and support function as a comprehensive solution for wholesale and retail providers — enabling systematic and automated management of inventory, invoicing, tracking, shipping, delivery chain, and more. Tahion.pro\'s expert engineers can custom-build a system perfectly suited to your business processes that will streamline and improve your operations thus helping you reach wider target audiences and boost conversion rates, leading to significantly increased sales.',
        },{
            id: 3,
            icon: education,
            title: 'Education',
            shortText: 'We\'re a little nerdy, so we know a lot about education. From e-learning and training portals to assessment and validation software...',
            text: 'We\'re a little nerdy, so we know a lot about education. From e-learning and training portals to assessment and validation software, a custom Tahion.pro solution will benefit your students and organization. Our tailored education products are designed to support your pedagogic methodologies and aims and being user-friendly, make complex processes easily accessible and intuitive. Tahion.pro software will enable you to better educate larger groups of students while concurrently freeing up resources and contributing to your organization\'s success.',
        },{
            id: 4,
            icon: ad,
            title: 'Advertisement',
            shortText: 'We all know advertising is costly, so when you invest in advertising you really want to ensure that your ads stand the best chances ...',
            text: 'We all know advertising is costly, so when you invest in advertising you really want to ensure that your ads stand the best chances of being effective, and nowadays great copywriting and content are only part of what\'s needed for that to happen. We\'ve designed, deployed, and maintained software solutions to optimize processes in the advertising industry, from personalization and prediction to engineering and implementation of textual and media ads delivery solutions. Harnessing the power of technology in the service of your advertising is virtually a must in today\'s marketplace, so let us help you do it, and do it incredibly well.',
        },{
            id: 5,
            icon: mobile_app,
            title: 'Mobile Apps',
            shortText: 'Our company provides app development services for different mobile app development technologies. The Tahion.pro helps you build custom mobile apps...',
            text: 'Our company provides app development services for different mobile app development technologies. The Tahion.pro helps you build custom mobile apps with great application development tools to make an app successful on the PlayStore or App Store.\nIt’s possible to achieve your business goals, expand your reach, and build your brand using intelligent technological solutions and skilled mobile and business app developers, and designers.\nWe offer native and cross-platform mobile solutions, KPI tracking, cloud support, and UX review, helping build easy-to-use, attention-retaining applications.',
        },
    ]);
    
    const onClick = (id: number) => {
        setIsOpenModal(true);
        setCurrentInd(listInds.find((e) => e.id === id));
    }
    
    return (
        <div className="IndustriesList">
            {listInds.map((item, index) => (
                <IndustriesItem key={item.title} onClick={onClick} {...item} />
            ))}

            <IndustriesModal
                isOpen={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                data={currentInd}
            />
        </div>
    );
}

export default IndustriesList;
