import React, {ChangeEvent, useState} from 'react';
import './Textarea.scss';

interface ITextareaProps {
    placeholder?: string,
    required?: boolean,
    value?: any,
    onChange?: Function,
    [key: string]: any,
} 

function Textarea(props: ITextareaProps) {
    const [value, setValue] = useState('');
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        if(props.onChange){
            props.onChange(e);
        }
    }
    return (
        <div className={'Textarea'}>
            <textarea {...props} required={props.required} rows={3} value={'value' in props ? props.value : value} onChange={onChange} placeholder={''} />
            {('value' in props ? !props.value : !value) && <span className={'placeholder'}>{props.placeholder}</span>}
        </div>
    );
}

export default Textarea;
