import {useEffect, useState} from 'react';

export function usePageScrolling() {
    const [sizeScrolling, setSizeScrolling] = useState(0);
    
    useEffect(() => {
        function doing(e: any) {
            const size = e.target.scrollingElement.scrollTop ?? 0;
            setSizeScrolling(size);
        }
        window.addEventListener('scroll', doing);
        return () =>{
            window.removeEventListener("scroll", doing);
        }
    })
    
    return sizeScrolling;
}