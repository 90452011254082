import React from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';

interface IModalProps {
    isOpen: boolean,
    closeModal: Function,
}

ReactModal.setAppElement('#root');

function Modal(props: React.PropsWithChildren<IModalProps>) {
    return (
        <ReactModal
            isOpen={props.isOpen}
            contentLabel="modal"
            onRequestClose={() => props.closeModal()}
            className="Modal-content"
            overlayClassName="Modal-overlay"
        >
            {props.children}
        </ReactModal>
    );
}

export default Modal;
