import React, {CSSProperties, useEffect} from 'react';
import './Hamburger.scss';

interface IHamburgerProps {
    showSideMenu: boolean,
    setShowSideMenu: Function,
    style?: CSSProperties,
}

function Hamburger(props: IHamburgerProps) {
    const hamburgerRef = React.useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if(!props.showSideMenu){
            hamburgerRef.current?.classList.remove("active");
        }
        else{
            hamburgerRef.current?.classList.add("active");
        }
    }, [props.showSideMenu])
    
    return (
        <div 
            ref={hamburgerRef}
            className="Hamburger"
            onClick={() => props.setShowSideMenu((old: any) => !old)}
            style={props.style}
        >
            <span/>
            <span/>
            <span/>
        </div>
    );
}

export default Hamburger;
