import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import './Header.scss';
import logo from '../../resources/logo-white.svg';
import comics_logo from '../../resources/comics/stories.png';
import {Motion, spring} from 'react-motion';
import {usePageScrolling} from '../../hooks/usePageScrolling';

interface IHeaderProps {
    showSideMenu: boolean,
    setShowSideMenu: Function,
}

function Header(props: IHeaderProps) {
    const defaultTopPos = 10;
    const sizeScrolling = usePageScrolling();
    const [topPos, setTopPos] = useState(sizeScrolling);
    
    useEffect(() => {
        if(sizeScrolling <= defaultTopPos) setTopPos((old) => defaultTopPos - sizeScrolling );
        else setTopPos(0);
    },[sizeScrolling]);
    
    return (
        <Motion defaultStyle={{top: defaultTopPos}} style={{top: spring(topPos)}}>
            {value => {
                return (
                    <>
                        <div className="Header" style={{
                        }}>
                            <div className="content">
                                <div className={'logo'}>
                                    <div>
                                        <Link to={'/'}>
                                            <img className={'logo-img'} src={logo}  alt={'logo'}/>
                                        </Link>
                                    </div>
                                </div>
                                <img className={'comics__logo__img'} src={comics_logo}  alt={'logo'}/>
                            </div>
                        </div>
                    </>
                )}
            }
        </Motion>
    );
}

export default Header;
