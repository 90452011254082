import React, {useState} from 'react';
import './OurClientsLogos.scss';
import Logo from "./Logo/Logo";
import cd_log from "../../../resources/client_logos/cd_log.svg";
import sneaker_street from "../../../resources/client_logos/sneaker_street.jpg";
import funda from "../../../resources/client_logos/funda.svg";
import wellcom from "../../../resources/client_logos/wellcom.svg";
import ppc_shield from "../../../resources/client_logos/ppc_shield.png";
import moto_shop from "../../../resources/client_logos/moto_shop.svg";
import highq from "../../../resources/client_logos/highq.svg";
import divolight from "../../../resources/client_logos/divolight.svg";
import brandpool from "../../../resources/client_logos/brandpool.png";
import diarossa from "../../../resources/client_logos/diarossa.jpg";
import babolat from "../../../resources/client_logos/babolat.svg";
import gamers_outlet from "../../../resources/client_logos/gamers_outlet.png";
import tms from "../../../resources/client_logos/tms.svg";
import nemesysco from "../../../resources/client_logos/nemesysco.svg";
import ofilsystems from "../../../resources/client_logos/ofilsystems.png";
import egoeast from "../../../resources/client_logos/egoeast.svg";
import sprkl from "../../../resources/client_logos/sprkl.png";
import AutoMoveCarousel from "../../../helpers/AutoMoveCarousel/AutoMoveCarousel";

function OurClientsLogos() {
    const [width] = useState(window.innerWidth > 786 ? 250 : 220);
    
    const listLogos = [
        {alt: 'cd_log', logo: cd_log},
        {alt: 'sneaker_street', logo: sneaker_street},
        {alt: 'funda', logo: funda},
        {alt: 'wellcom', logo: wellcom},
        {alt: 'ppc_shield', logo: ppc_shield},
        {alt: 'moto_shop', logo: moto_shop},
        {alt: 'highq', logo: highq},
        {alt: 'divolight', logo: divolight},
        {alt: 'brandpool', logo: brandpool},
        {alt: 'diarossa', logo: diarossa},
        {alt: 'babolat', logo: babolat},
        {alt: 'gamers_outlet', logo: gamers_outlet},
        {alt: 'tms', logo: tms},
        {alt: 'nemesysco', logo: nemesysco},
        {alt: 'ofilsystems', logo: ofilsystems},
        {alt: 'sprkl', logo: sprkl},
        {alt: 'egoeast', logo: egoeast},
    ];

    return (
        <div className="OurClientsLogos">
            <AutoMoveCarousel items={listLogos} itemRender={Logo} itemWidth={width} />
        </div>
    );
} 

export default OurClientsLogos;
