import React, {ChangeEvent, useState} from 'react';
import './Input.scss';

interface IInputProps {
    placeholder?: string,
    required?: boolean,
    value?: any,
    onChange?: Function,
    [key: string]: any,
} 

function Input(props: IInputProps) {
    const [value, setValue] = useState('');
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if(props.onChange){
            props.onChange(e);
        }
    }
    
    return (
        <div className={'Input'}>
            <input {...props} value={'value' in props ? props.value : value} onChange={onChange} placeholder={''}/>
            {('value' in props ? !props.value : !value) && <span className={'placeholder'}>{props.placeholder}</span>}
        </div>
    );
}

export default Input;
