import React from 'react';
import './OurClients.scss';
import OurClientsLogos from "./OurClientsLogos/OurClientsLogos";
import Map from "./Map/Map";
 
function OurClients() {

    return (
        <div className="OurClients modal-bg">
            <div className="content">
                <div className={'titleKey'}>our clients</div>
                <div className={'title'}>Who do We Serve</div>
                <div className={'underTitleText'}>
                    <span>We serve the following business sectors: </span>
                    <span className={'text-gradient'}>E-Commerce & Finance, Wholesale & Retail, Education, Advertisement. </span>
                    <span>Among our clients are:</span>
                </div>
            </div>
            <OurClientsLogos />
            <div className="content">
                <Map />
            </div>
        </div>
    );
}

export default OurClients;
