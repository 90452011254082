import React, {createRef} from 'react';
import './AnimatedImage.scss';
import object from './3d-object-min.png';

function AnimatedImage() {
    const aRef = createRef<HTMLAnchorElement>();
    
    const onMouseOver = function (){
        aRef.current?.classList.add('hovered');
    }

    const onMouseLeave = function (){
        aRef.current?.classList.remove('hovered');
        aRef.current?.classList.add('unhovered');
        setTimeout(() => {
            aRef.current?.classList.remove('unhovered');
        }, 600);
    }
    
    return (
        <div className="AnimatedImage">
            <div className={'back'}>
                <div className={'downloadPortfolio'}>
                    <a 
                        ref={aRef}
                        onMouseOver={onMouseOver}
                        onMouseLeave={onMouseLeave}
                        href="/Tahion PORTFOLIO Final1.pdf"
                        className="portfolio-link" 
                        target="_blank"
                        rel="noreferrer"
                    >
                        <svg width="254" height="254" viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="127" cy="127" r="60" fill="white" id="circlePath"/>
                            <mask id="mask0_118_429" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="67" y="67" width="120" height="120">
                                <circle cx="127" cy="127" r="60" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_118_429)">
                                <path opacity="0.5" d="M186.5 146.5L180.526 156.327C180.526 169.954 169.517 181 155.934 181L143.568 186L94.4106 138.427L143.568 105.968C143.572 105.973 185.668 145.665 185.668 145.665L186.5 146.5Z" fill="url(#paint0_linear_118_429)"/>
                                <path opacity="0.3" d="M152.345 121.481C151.488 121.481 150.656 121.582 149.856 121.766C149.495 110.545 140.241 101.558 128.877 101.558C119.594 101.558 111.719 107.555 108.949 115.867C107.679 115.493 106.336 115.29 104.945 115.29C97.1618 115.29 90.8525 121.568 90.8525 129.313C90.8525 137.058 97.1618 143.337 104.945 143.337C109.162 143.337 148.329 143.337 152.345 143.337C158.41 143.337 163.326 138.444 163.326 132.409C163.326 126.374 158.41 121.481 152.345 121.481Z" fill="#1A1A1A"/>
                                <path d="M150.769 120.628C149.923 120.628 149.1 120.729 148.31 120.914C147.952 109.692 138.807 100.705 127.577 100.705C118.403 100.705 110.621 106.702 107.884 115.015C106.629 114.641 105.302 114.437 103.926 114.437C96.235 114.437 90 120.716 90 128.461C90 136.206 96.235 142.484 103.926 142.484C108.094 142.484 146.8 142.484 150.769 142.484C156.762 142.484 161.621 137.592 161.621 131.556C161.621 125.521 156.762 120.628 150.769 120.628Z" fill="url(#paint1_linear_118_429)"/>
                                <path d="M150.769 118.923C149.923 118.923 149.1 119.024 148.31 119.208C147.952 107.987 138.807 99 127.577 99C118.403 99 110.621 104.997 107.884 113.31C106.629 112.936 105.302 112.732 103.926 112.732C96.235 112.732 90 119.01 90 126.755C90 134.5 96.235 140.779 103.926 140.779C108.094 140.779 146.8 140.779 150.769 140.779C156.762 140.779 161.621 135.886 161.621 129.851C161.621 123.816 156.762 118.923 150.769 118.923Z" fill="white"/>
                                <path d="M103.669 139.926C96.1319 139.926 90 133.826 90 126.327C90 118.828 96.1319 112.727 103.669 112.727C104.984 112.727 106.291 112.915 107.553 113.287L107.944 113.402L108.072 113.017C110.867 104.633 118.714 99 127.598 99C132.975 99 138.06 101.051 141.918 104.776C145.767 108.492 147.982 113.471 148.153 118.796L148.17 119.305L148.669 119.19C149.458 119.008 150.263 118.916 151.062 118.916C156.884 118.916 161.621 123.629 161.621 129.421C161.621 135.214 156.884 139.926 151.062 139.926H103.669Z" fill="url(#paint2_linear_118_429)"/>
                                <path opacity="0.3" d="M143.71 134.967C143.53 131.224 138.849 129.201 136.108 131.986L131.551 136.616V116.244C131.551 115.462 131.356 114.727 131.012 114.085C129.576 111.396 125.731 111.029 123.791 113.198C123.071 114.002 122.632 115.071 122.632 116.244V136.616L118.075 131.986C117.205 131.101 116.064 130.658 114.922 130.658C112.324 130.658 110.735 132.662 110.463 134.967V137.404H110.47C110.434 138.609 110.864 139.826 111.769 140.746L123.938 153.112C125.656 154.856 128.528 154.859 130.245 153.112L142.414 140.746C143.319 139.826 143.75 138.609 143.714 137.404C143.717 137.127 143.714 135.237 143.71 134.967C143.71 134.967 143.714 135.237 143.71 134.967C143.71 134.967 143.714 135.237 143.71 134.967C143.71 134.967 143.714 135.237 143.71 134.967Z" fill="#1A1A1A"/>
                                <path d="M142.863 133.84H142.05C141.895 133.616 141.722 133.401 141.524 133.201C140.631 132.304 139.461 131.855 138.29 131.855C137.12 131.855 135.95 132.304 135.057 133.201L130.383 137.898V117.237C130.383 114.7 128.336 112.642 125.81 112.642C123.285 112.642 121.237 114.699 121.237 117.237V137.897L116.564 133.201C114.778 131.407 111.883 131.407 110.097 133.201C109.899 133.401 109.726 133.616 109.571 133.84H108.758V136.31H108.765C108.728 137.533 109.169 138.767 110.097 139.7L122.577 152.241C124.338 154.009 127.283 154.013 129.044 152.241L141.524 139.7C142.452 138.767 142.893 137.533 142.856 136.31H142.863V133.84H142.863Z" fill="url(#paint3_linear_118_429)"/>
                                <path d="M141.524 137.142L129.044 149.683C127.282 151.453 124.338 151.453 122.577 149.683L110.097 137.142C108.311 135.347 108.311 132.438 110.097 130.643C111.883 128.849 114.778 128.849 116.564 130.643L121.237 135.339V114.68C121.237 112.142 123.285 110.084 125.81 110.084C128.336 110.084 130.383 112.142 130.383 114.68V135.34L135.057 130.643C135.95 129.746 137.12 129.297 138.29 129.297C139.461 129.297 140.631 129.746 141.524 130.643C143.309 132.438 143.309 135.347 141.524 137.142Z" fill="url(#paint4_linear_118_429)"/>
                                <path d="M126.237 150.158C125.149 150.158 124.076 149.707 123.306 148.967L110.825 136.697C109.206 135.105 109.206 132.515 110.825 130.923C111.609 130.152 112.652 129.728 113.761 129.728C114.87 129.728 115.913 130.152 116.697 130.923L122.084 136.219V115.019C122.084 112.768 123.947 110.937 126.237 110.937C128.526 110.937 130.389 112.768 130.389 115.019V136.219L135.776 130.923C136.561 130.152 137.603 129.728 138.712 129.728C139.822 129.728 140.864 130.152 141.649 130.923C143.268 132.515 143.268 135.105 141.649 136.697L129.17 148.965C128.399 149.705 127.324 150.158 126.237 150.158Z" fill="url(#paint5_linear_118_429)"/>
                            </g>
                            <path id="text" d="M187.169 93.3012L198.022 88.422L199.472 91.6471C200.178 93.2183 200.281 94.7316 199.78 96.1871C199.283 97.653 198.187 98.7669 196.492 99.529C194.797 100.291 193.236 100.371 191.81 99.7699C190.389 99.1787 189.325 98.0975 188.618 96.5263L187.169 93.3012ZM189.067 94.1251L189.869 95.9082C190.385 97.0556 191.161 97.8561 192.197 98.3097C193.238 98.7736 194.451 98.6942 195.837 98.0715C196.777 97.6487 197.48 97.0968 197.944 96.4159C198.423 95.7407 198.665 95.048 198.669 94.338C198.683 93.6233 198.525 92.8991 198.195 92.1651L197.394 90.382L189.067 94.1251Z" fill="white"/>
                            <path id="text" d="M195.331 112.607C194.341 111.999 193.703 111.077 193.416 109.84C193.13 108.603 193.297 107.494 193.919 106.513C194.552 105.528 195.492 104.892 196.739 104.602C197.987 104.313 199.106 104.473 200.096 105.081C201.097 105.687 201.741 106.608 202.028 107.844C202.314 109.081 202.141 110.191 201.508 111.176C200.889 112.168 199.956 112.809 198.708 113.098C197.461 113.387 196.335 113.224 195.331 112.607ZM195.051 107.332C194.608 107.982 194.483 108.72 194.675 109.548C194.867 110.376 195.304 110.985 195.988 111.373C196.682 111.759 197.476 111.848 198.371 111.641C199.265 111.434 199.933 111.005 200.376 110.356C200.83 109.704 200.961 108.964 200.769 108.136C200.577 107.308 200.134 106.701 199.44 106.315C198.756 105.927 197.968 105.836 197.073 106.043C196.179 106.251 195.505 106.68 195.051 107.332Z" fill="white"/>
                            <path id="text" d="M195.535 120.079L204.022 117.209L204.066 118.687L197.579 120.733L204.18 122.544L204.221 123.938L197.738 126.136L204.335 127.778L204.38 129.29L195.737 126.91L195.696 125.499L202.145 123.302L195.577 121.489L195.535 120.079Z" fill="white"/>
                            <path id="text" d="M195.595 133.319L204.047 134.724L203.81 136.15L202.217 135.885C202.692 136.274 203.044 136.746 203.274 137.301C203.503 137.857 203.566 138.447 203.462 139.073C203.315 139.957 202.921 140.632 202.281 141.1C201.638 141.579 200.869 141.744 199.975 141.596L194.374 140.665L194.611 139.239L199.944 140.126C200.525 140.222 201.023 140.127 201.439 139.84C201.865 139.555 202.127 139.121 202.224 138.54C202.356 137.746 202.136 137.061 201.566 136.483C201.005 135.919 200.171 135.545 199.064 135.361L195.358 134.745L195.595 133.319Z" fill="white"/>
                            <path id="text" d="M193.372 144.782L204.947 148.078L204.552 149.468L192.976 146.171L193.372 144.782Z" fill="white"/>
                            <path id="text" d="M190.593 155.855C190.154 154.779 190.184 153.658 190.684 152.491C191.184 151.324 191.975 150.529 193.056 150.104C194.149 149.685 195.283 149.727 196.461 150.231C197.638 150.736 198.446 151.526 198.885 152.602C199.335 153.682 199.309 154.805 198.809 155.972C198.31 157.139 197.514 157.932 196.421 158.352C195.335 158.787 194.203 158.752 193.026 158.248C191.849 157.743 191.038 156.946 190.593 155.855ZM193.485 151.435C192.744 151.697 192.206 152.218 191.871 153C191.537 153.781 191.53 154.53 191.852 155.248C192.184 155.969 192.771 156.511 193.615 156.872C194.459 157.234 195.251 157.284 195.993 157.022C196.744 156.764 197.287 156.245 197.622 155.463C197.957 154.682 197.958 153.931 197.626 153.209C197.305 152.492 196.722 151.952 195.878 151.591C195.034 151.229 194.237 151.177 193.485 151.435Z" fill="white"/>
                            <path id="text" d="M192.716 167.984C192.141 168.868 191.435 169.422 190.598 169.648C189.761 169.874 188.929 169.717 188.103 169.179L183.502 166.182L184.282 164.985L185.507 165.783C185.235 165.308 185.117 164.772 185.155 164.175C185.196 163.593 185.387 163.04 185.727 162.518C186.191 161.806 186.766 161.349 187.452 161.147C188.142 160.96 188.8 161.071 189.427 161.479C190.775 162.358 190.821 164.011 189.564 166.438L188.811 167.936L188.954 168.029C189.448 168.351 189.942 168.443 190.437 168.306C190.936 168.184 191.358 167.857 191.705 167.326C192.027 166.832 192.155 166.334 192.09 165.832C192.025 165.329 191.766 164.897 191.312 164.534L192.259 163.548C192.903 164.116 193.266 164.805 193.347 165.615C193.421 166.435 193.211 167.225 192.716 167.984ZM186.603 163.353C186.152 164.046 186.013 164.727 186.186 165.394C186.36 166.062 186.808 166.631 187.53 167.101L187.871 167.324L188.624 165.826C189.422 164.249 189.431 163.207 188.652 162.699C188.32 162.483 187.961 162.432 187.576 162.546C187.193 162.675 186.869 162.945 186.603 163.353Z" fill="white"/>
                            <path id="text" d="M178.247 172.644C179.131 171.702 180.123 171.241 181.222 171.262C182.321 171.298 183.347 171.762 184.297 172.654C185.248 173.545 185.773 174.535 185.872 175.622C185.98 176.717 185.592 177.736 184.708 178.678C184.32 179.092 183.861 179.399 183.33 179.601C182.792 179.81 182.281 179.875 181.797 179.794L185.22 183.003L184.232 184.058L175.451 175.825L176.44 174.771L177.345 175.62C177.187 175.208 177.192 174.723 177.358 174.164C177.525 173.606 177.821 173.099 178.247 172.644ZM181.101 172.733C180.339 172.717 179.671 173.015 179.097 173.627C178.523 174.239 178.269 174.925 178.333 175.684C178.406 176.452 178.786 177.157 179.472 177.8C180.158 178.444 180.882 178.773 181.645 178.789C182.415 178.812 183.087 178.518 183.661 177.906C184.234 177.294 184.485 176.605 184.412 175.837C184.347 175.078 183.972 174.376 183.286 173.733C182.6 173.089 181.871 172.756 181.101 172.733Z" fill="white"/>
                            <path id="text" d="M167.462 182.771L173.758 192.869L169.921 195.262C168.94 195.873 167.988 196.093 167.065 195.92C166.149 195.756 165.415 195.232 164.863 194.348C164.312 193.463 164.161 192.568 164.412 191.664C164.669 190.769 165.288 190.016 166.269 189.405L168.794 187.831L166.15 183.589L167.462 182.771ZM169.531 189.014L167.05 190.561C166.454 190.932 166.083 191.377 165.938 191.895C165.784 192.418 165.878 192.954 166.219 193.502C166.561 194.05 167.001 194.37 167.539 194.462C168.074 194.57 168.639 194.438 169.235 194.066L171.717 192.519L169.531 189.014Z" fill="white"/>
                            <path id="text" d="M151.445 192.456C151.896 191.385 152.711 190.614 153.889 190.143C155.068 189.672 156.19 189.669 157.254 190.135C158.323 190.611 159.095 191.443 159.571 192.632C160.046 193.822 160.058 194.952 159.608 196.023C159.161 197.104 158.348 197.881 157.17 198.352C155.991 198.823 154.867 198.82 153.798 198.344C152.723 197.883 151.948 197.058 151.472 195.869C150.997 194.68 150.988 193.542 151.445 192.456ZM156.616 191.378C155.907 191.039 155.158 191.027 154.369 191.343C153.579 191.658 153.045 192.183 152.765 192.918C152.489 193.663 152.521 194.461 152.862 195.314C153.202 196.166 153.727 196.762 154.436 197.101C155.15 197.45 155.901 197.468 156.69 197.152C157.48 196.837 158.012 196.306 158.288 195.562C158.568 194.827 158.538 194.034 158.197 193.181C157.857 192.329 157.33 191.728 156.616 191.378Z" fill="white"/>
                            <path id="text" d="M142.302 201.396C142.642 201.455 143.016 201.437 143.424 201.341C144.13 201.174 144.629 200.696 144.92 199.905C145.202 199.128 145.19 198.089 144.883 196.787L144.134 193.61L145.541 193.279L147.507 201.618L146.1 201.95L145.702 200.262C145.585 200.849 145.323 201.37 144.919 201.827C144.514 202.283 144.052 202.572 143.534 202.695C143.17 202.78 142.838 202.806 142.539 202.772L142.302 201.396Z" fill="white"/>
                            <path id="text" d="M133.199 195.573C133.555 195.396 133.993 195.279 134.511 195.221C135.277 195.136 135.915 195.287 136.426 195.674C136.927 196.075 137.228 196.725 137.328 197.626L137.853 202.323L139.441 202.146L139.583 203.413L137.995 203.59L138.283 206.175L136.847 206.336L136.559 203.751L134.278 204.005L134.136 202.738L136.417 202.484L135.887 197.736C135.837 197.297 135.688 196.977 135.437 196.777C135.187 196.577 134.87 196.498 134.487 196.541C134.07 196.588 133.724 196.7 133.447 196.88L133.199 195.573Z" fill="white"/>
                            <path id="text" d="M128.9 195.774L128.873 203.067L130.335 203.073L130.331 204.348L128.869 204.342L128.866 205.141C128.862 206.059 128.627 206.767 128.161 207.264C127.694 207.772 127.081 208.025 126.322 208.022C125.869 208.02 125.484 207.974 125.167 207.882L125.239 206.59C125.534 206.704 125.834 206.762 126.14 206.763C126.525 206.765 126.837 206.624 127.076 206.342C127.304 206.071 127.419 205.708 127.42 205.255L127.424 204.337L125.231 204.329L125.235 203.054L127.428 203.062L127.455 195.769L128.9 195.774Z" fill="white"/>
                            <path id="text" d="M114.828 195.815C115.761 195.122 116.855 194.873 118.109 195.068C119.364 195.262 120.331 195.831 121.01 196.773C121.688 197.727 121.929 198.837 121.733 200.102C121.537 201.368 120.972 202.347 120.039 203.039C119.104 203.743 118.01 203.998 116.755 203.803C115.501 203.609 114.535 203.035 113.857 202.081C113.166 201.137 112.919 200.032 113.115 198.766C113.311 197.501 113.882 196.517 114.828 195.815ZM119.828 197.519C119.39 196.866 118.752 196.475 117.912 196.344C117.072 196.214 116.344 196.394 115.729 196.884C115.113 197.384 114.734 198.088 114.593 198.995C114.453 199.903 114.601 200.683 115.039 201.335C115.475 201.999 116.113 202.396 116.953 202.527C117.793 202.657 118.522 202.472 119.138 201.971C119.753 201.481 120.131 200.783 120.271 199.876C120.412 198.969 120.264 198.183 119.828 197.519Z" fill="white"/>
                            <path id="text" d="M109.23 193.437L105.933 205.012L104.544 204.617L107.84 193.041L109.23 193.437Z" fill="white"/>
                            <path id="text" d="M100.806 203.451L99.2394 202.885L99.8629 201.158L101.43 201.724L100.806 203.451ZM104.798 192.096L101.888 200.154L100.529 199.664L103.439 191.605L104.798 192.096Z" fill="white"/>
                            <path id="text" d="M93.9952 188.614C95.0976 188.247 96.2145 188.351 97.3458 188.926C98.4772 189.502 99.219 190.343 99.5711 191.451C99.9182 192.568 99.8013 193.698 99.2207 194.839C98.64 195.981 97.7984 196.735 96.696 197.102C95.5885 197.48 94.469 197.381 93.3376 196.805C92.2063 196.23 91.4671 195.383 91.1201 194.266C90.7578 193.153 90.867 192.026 91.4477 190.885C92.0283 189.743 92.8775 188.986 93.9952 188.614ZM98.2155 191.791C98.0028 191.034 97.5176 190.463 96.76 190.078C96.0024 189.692 95.2552 189.637 94.5184 189.91C93.7764 190.194 93.1973 190.745 92.781 191.563C92.3648 192.381 92.263 193.169 92.4757 193.925C92.6833 194.692 93.1659 195.268 93.9235 195.654C94.6811 196.039 95.4309 196.09 96.1728 195.806C96.9097 195.533 97.4862 194.987 97.9024 194.168C98.3187 193.35 98.423 192.558 98.2155 191.791Z" fill="white"/>
                            <path id="text" d="M76.3152 179.388C76.5812 179.65 76.7633 179.958 76.8614 180.31C76.9515 180.654 76.9575 181.003 76.8794 181.356C76.7852 181.709 76.6027 182.023 76.3318 182.297C76.0688 182.562 75.7653 182.741 75.4212 182.831C75.0611 182.921 74.7085 182.923 74.3634 182.837C74.0023 182.751 73.6888 182.576 73.4228 182.313C73.1568 182.05 72.9828 181.743 72.9006 181.39C72.8025 181.038 72.8005 180.686 72.8946 180.333C72.9727 179.98 73.1433 179.67 73.4062 179.404C73.6851 179.122 74.0046 178.936 74.3647 178.846C74.7169 178.764 75.0695 178.77 75.4227 178.864C75.7598 178.958 76.0573 179.133 76.3152 179.388ZM75.873 179.835C75.5667 179.532 75.2213 179.39 74.8368 179.408C74.4442 179.434 74.1165 179.58 73.8535 179.846C73.5826 180.12 73.4483 180.458 73.4506 180.858C73.4529 181.259 73.591 181.595 73.865 181.866C74.139 182.137 74.4764 182.271 74.8771 182.269C75.2778 182.266 75.6136 182.128 75.8845 181.854C76.1634 181.572 76.3057 181.235 76.3115 180.842C76.3093 180.457 76.1631 180.122 75.873 179.835Z" fill="white"/>
                            <path id="text" d="M65.6326 158.656L54.6166 163.157L53.2792 159.884C52.6276 158.289 52.5775 156.773 53.1289 155.336C53.6759 153.888 54.8097 152.813 56.5303 152.11C58.2509 151.407 59.8134 151.38 61.2178 152.031C62.6178 152.671 63.6436 153.788 64.2952 155.383L65.6326 158.656ZM63.7635 157.767L63.024 155.957C62.5482 154.793 61.8003 153.966 60.7801 153.477C59.7557 152.977 58.5406 153.014 57.1347 153.589C56.18 153.979 55.4589 154.506 54.9713 155.171C54.4689 155.829 54.2035 156.513 54.1751 157.222C54.1361 157.936 54.2688 158.665 54.5732 159.41L55.3126 161.22L63.7635 157.767Z" fill="white"/>
                            <path id="text" d="M58.209 139.448C59.1803 140.086 59.7906 141.027 60.0397 142.272C60.2887 143.516 60.0877 144.62 59.4366 145.582C58.7744 146.547 57.8154 147.155 56.5596 147.406C55.3038 147.658 54.1902 147.464 53.2189 146.827C52.2364 146.191 51.6206 145.251 51.3715 144.006C51.1224 142.762 51.329 141.657 51.9913 140.692C52.6401 139.719 53.5925 139.106 54.8482 138.855C56.104 138.604 57.2243 138.801 58.209 139.448ZM58.3296 144.729C58.7918 144.093 58.9396 143.359 58.7728 142.525C58.606 141.692 58.1869 141.071 57.5156 140.662C56.8332 140.255 56.042 140.142 55.1418 140.322C54.2417 140.502 53.5605 140.91 53.0982 141.546C52.6249 142.184 52.4716 142.919 52.6384 143.753C52.8052 144.586 53.2298 145.206 53.9122 145.613C54.5835 146.022 55.3692 146.136 56.2694 145.956C57.1695 145.776 57.8563 145.367 58.3296 144.729Z" fill="white"/>
                            <path id="text" d="M58.2018 131.892L49.6243 134.479L49.6297 133L56.1814 131.171L49.6438 129.141L49.6489 127.747L56.2012 125.765L49.663 123.905L49.6685 122.392L58.2268 125.058L58.2217 126.469L51.7034 128.451L58.207 130.481L58.2018 131.892Z" fill="white"/>
                            <path id="text" d="M58.5148 118.896L50.1072 117.246L50.3856 115.828L51.9703 116.139C51.5066 115.736 51.1681 115.254 50.955 114.692C50.7418 114.131 50.6964 113.539 50.8186 112.916C50.9911 112.037 51.4044 111.373 52.0584 110.924C52.7147 110.464 53.4877 110.321 54.3774 110.496L59.949 111.59L59.6707 113.008L54.3659 111.966C53.7876 111.853 53.2867 111.933 52.8632 112.208C52.4285 112.481 52.1544 112.907 52.0409 113.485C51.8859 114.274 52.0851 114.966 52.6384 115.56C53.1828 116.14 54.0055 116.538 55.1065 116.755L58.7931 117.478L58.5148 118.896Z" fill="white"/>
                            <path id="text" d="M61.068 107.429L49.6062 103.756L50.0472 102.38L61.509 106.053L61.068 107.429Z" fill="white"/>
                            <path id="text" d="M64.307 96.1344C64.7106 97.2241 64.6433 98.3438 64.1054 99.4935C63.5674 100.643 62.7509 101.412 61.6557 101.801C60.5502 102.184 59.4175 102.105 58.2575 101.562C57.0976 101.019 56.3158 100.203 55.9123 99.1133C55.4985 98.0188 55.5606 96.8967 56.0985 95.747C56.6365 94.5973 57.4582 93.8306 58.5637 93.447C59.6637 93.0482 60.7936 93.1203 61.9536 93.663C63.1136 94.2058 63.898 95.0296 64.307 96.1344ZM61.2709 100.457C62.0202 100.22 62.5749 99.7158 62.9352 98.9459C63.2954 98.176 63.3266 97.4274 63.0289 96.7C62.7208 95.9677 62.1511 95.4071 61.3196 95.018C60.4881 94.629 59.6977 94.5532 58.9484 94.7907C58.1889 95.0234 57.629 95.5247 57.2688 96.2946C56.9085 97.0645 56.8824 97.8155 57.1905 98.5477C57.4883 99.2752 58.0529 99.8334 58.8844 100.222C59.7158 100.612 60.5113 100.69 61.2709 100.457Z" fill="white"/>
                            <path id="text" d="M62.4259 84.1576C63.0304 83.2941 63.7547 82.7636 64.5987 82.5659C65.4427 82.3682 66.2685 82.5521 67.0762 83.1176L71.5744 86.2668L70.7554 87.4366L69.5578 86.5981C69.814 87.0818 69.9133 87.6217 69.8557 88.2178C69.7953 88.7981 69.5863 89.3436 69.2288 89.8542C68.7414 90.5505 68.1513 90.9882 67.4586 91.1674C66.7632 91.3307 66.1091 91.1979 65.4964 90.7689C64.178 89.8459 64.1874 88.1923 65.5246 85.8081L66.3269 84.3361L66.1876 84.2386C65.7048 83.9006 65.2137 83.792 64.7142 83.9126C64.2119 84.0175 63.7787 84.3299 63.4147 84.8498C63.0767 85.3326 62.9318 85.8261 62.9801 86.3302C63.0283 86.8344 63.2729 87.2754 63.7139 87.6533L62.7348 88.6073C62.11 88.0176 61.7707 87.3166 61.7169 86.5042C61.6696 85.6825 61.906 84.9003 62.4259 84.1576ZM68.3808 88.9907C68.8553 88.3129 69.0169 87.6375 68.8656 86.9643C68.7143 86.2912 68.2858 85.7076 67.5802 85.2136L67.246 84.9796L66.4437 86.4516C65.594 88.0012 65.5499 89.0424 66.3112 89.5754C66.6361 89.8029 66.9931 89.8661 67.3821 89.7649C67.7684 89.648 68.1013 89.3899 68.3808 88.9907Z" fill="white"/>
                            <path id="text" d="M77.0072 79.939C76.0919 80.8509 75.0854 81.2777 73.9876 81.2196C72.8898 81.1454 71.881 80.6466 70.9612 79.7233C70.0413 78.7999 69.5503 77.7933 69.4883 76.7033C69.4182 75.6052 69.8409 74.6003 70.7562 73.6884C71.1576 73.2885 71.627 72.9969 72.1643 72.8136C72.7096 72.6223 73.2225 72.5751 73.7032 72.6722L70.3917 69.3482L71.4154 68.3284L79.9101 76.8552L78.8864 77.875L78.0105 76.9958C78.154 77.4128 78.133 77.8976 77.9477 78.4502C77.7623 79.0028 77.4488 79.4991 77.0072 79.939ZM74.1586 79.7534C74.9199 79.7949 75.5976 79.5197 76.1917 78.9278C76.7859 78.3358 77.0636 77.6592 77.025 76.8978C76.9784 76.1284 76.6231 75.4105 75.9592 74.7441C75.2953 74.0777 74.5827 73.7237 73.8215 73.6822C73.0523 73.6327 72.3706 73.9038 71.7764 74.4957C71.1823 75.0877 70.9085 75.7683 70.9551 76.5377C70.9938 77.2991 71.345 78.013 72.0089 78.6794C72.6728 79.3458 73.3894 79.7038 74.1586 79.7534Z" fill="white"/>
                            <path id="text" d="M87.9639 70.193L81.9831 59.9052L85.8924 57.6325C86.8918 57.0515 87.8499 56.8616 88.7665 57.0628C89.6775 57.2542 90.395 57.8007 90.919 58.7021C91.443 59.6035 91.5657 60.5023 91.287 61.3984C91.0026 62.2848 90.3607 63.0184 89.3613 63.5994L86.7894 65.0946L89.3013 69.4155L87.9639 70.193ZM86.0888 63.8895L88.6166 62.4199C89.2241 62.0667 89.6082 61.6337 89.7688 61.1208C89.9393 60.6022 89.8622 60.0637 89.5375 59.5052C89.2128 58.9467 88.783 58.6132 88.248 58.5048C87.7171 58.3808 87.1479 58.4954 86.5404 58.8485L84.0126 60.3181L86.0888 63.8895Z" fill="white"/>
                            <path id="text" d="M104.183 60.9822C103.7 62.0389 102.862 62.7846 101.669 63.2193C100.476 63.654 99.3551 63.6224 98.3051 63.1246C97.2512 62.616 96.505 61.7602 96.0665 60.5569C95.6279 59.3537 95.6503 58.2237 96.1336 57.167C96.6131 56.0996 97.4491 55.3485 98.6417 54.9138C99.8343 54.4792 100.957 54.5161 102.011 55.0246C103.072 55.5186 103.822 56.3672 104.26 57.5704C104.699 58.7736 104.673 59.9109 104.183 60.9822ZM98.981 61.9011C99.6794 62.2618 100.428 62.2965 101.227 62.0055C102.025 61.7144 102.576 61.2061 102.878 60.4806C103.177 59.7445 103.169 58.9452 102.855 58.0827C102.54 57.2202 102.034 56.6087 101.335 56.248C100.633 55.8767 99.8827 55.8366 99.0841 56.1277C98.2855 56.4188 97.7369 56.9324 97.4383 57.6685C97.1357 58.394 97.1417 59.188 97.456 60.0505C97.7704 60.9129 98.2787 61.5298 98.981 61.9011Z" fill="white"/>
                            <path id="text" d="M113.749 52.2532C113.411 52.1821 113.037 52.1877 112.626 52.27C111.915 52.4123 111.4 52.8736 111.082 53.6538C110.773 54.4206 110.75 55.4597 111.013 56.7711L111.653 59.9716L110.236 60.2552L108.555 51.8537L109.972 51.5702L110.312 53.2705C110.45 52.6881 110.729 52.1758 111.149 51.7334C111.569 51.2911 112.04 51.0176 112.562 50.9131C112.929 50.8397 113.261 50.8252 113.559 50.8696L113.749 52.2532Z" fill="white"/>
                            <path id="text" d="M122.47 58.343C122.109 58.509 121.668 58.6132 121.148 58.6556C120.38 58.7183 119.747 58.5483 119.248 58.1456C118.759 57.7306 118.478 57.0713 118.404 56.1677L118.019 51.4574L116.427 51.5874L116.323 50.3166L117.916 50.1866L117.704 47.5942L119.144 47.4766L119.356 50.069L121.643 49.8823L121.747 51.153L119.46 51.3398L119.848 56.1009C119.884 56.5415 120.024 56.8655 120.269 57.0729C120.513 57.2804 120.827 57.3685 121.211 57.3371C121.629 57.303 121.979 57.2005 122.261 57.0297L122.47 58.343Z" fill="white"/>
                            <path id="text" d="M126.972 58.2345L127.241 50.9465L125.78 50.8927L125.826 49.6186L127.287 49.6723L127.317 48.8739C127.351 47.9565 127.609 47.2572 128.092 46.7759C128.575 46.2833 129.196 46.051 129.954 46.0789C130.407 46.0956 130.791 46.1551 131.105 46.2573L130.989 47.546C130.699 47.4219 130.401 47.3542 130.095 47.343C129.71 47.3288 129.393 47.4589 129.145 47.7333C128.909 47.9968 128.782 48.355 128.765 48.8081L128.732 49.7254L130.923 49.8061L130.876 51.0802L128.685 50.9996L128.417 58.2877L126.972 58.2345Z" fill="white"/>
                            <path id="text" d="M141.105 58.6203C140.15 59.2819 139.049 59.495 137.801 59.2596C136.554 59.0241 135.606 58.4243 134.958 57.4599C134.311 56.4845 134.107 55.3675 134.345 54.1091C134.582 52.8506 135.179 51.8906 136.134 51.229C137.091 50.5562 138.194 50.3375 139.441 50.573C140.688 50.8084 141.635 51.4139 142.281 52.3893C142.941 53.3557 143.152 54.4682 142.914 55.7266C142.677 56.9851 142.074 57.9496 141.105 58.6203ZM136.164 56.7534C136.58 57.4201 137.206 57.8323 138.041 57.99C138.876 58.1477 139.609 57.9919 140.24 57.5227C140.872 57.0424 141.274 56.3512 141.444 55.4492C141.614 54.5471 141.491 53.7627 141.075 53.0959C140.661 52.418 140.036 52.0002 139.201 51.8426C138.366 51.6849 137.632 51.8462 136.999 52.3265C136.369 52.7957 135.968 53.4813 135.798 54.3834C135.628 55.2855 135.75 56.0755 136.164 56.7534Z" fill="white"/>
                            <path id="text" d="M146.591 61.1372L150.264 49.6754L151.64 50.1164L147.967 61.5782L146.591 61.1372Z" fill="white"/>
                            <path id="text" d="M155.181 51.3258L156.731 51.937L156.057 53.645L154.507 53.0338L155.181 51.3258ZM150.86 62.5601L154.004 54.5895L155.348 55.1196L152.205 63.0902L150.86 62.5601Z" fill="white"/>
                            <path id="text" d="M161.919 66.5341C160.805 66.8649 159.692 66.724 158.58 66.1115C157.469 65.4989 156.755 64.6334 156.44 63.515C156.13 62.3866 156.284 61.2616 156.902 60.14C157.52 59.0183 158.386 58.2921 159.5 57.9613C160.619 57.6205 161.735 57.7565 162.847 58.3691C163.958 58.9816 164.669 59.8521 164.979 60.9805C165.304 62.1043 165.158 63.2271 164.54 64.3488C163.922 65.4704 163.048 66.1989 161.919 66.5341ZM157.806 63.2196C157.993 63.9829 158.459 64.5697 159.204 64.9799C159.948 65.3901 160.693 65.4706 161.439 65.2215C162.19 64.9624 162.787 64.4308 163.23 63.6268C163.673 62.8228 163.8 62.0391 163.613 61.2758C163.431 60.5026 162.967 59.9109 162.223 59.5006C161.479 59.0904 160.731 59.0149 159.98 59.274C159.234 59.5231 158.64 60.0497 158.197 60.8537C157.754 61.6577 157.624 62.4464 157.806 63.2196Z" fill="white"/>
                            <path id="text" d="M179.103 76.1404C178.845 75.8695 178.672 75.5567 178.585 75.2018C178.505 74.8551 178.51 74.5065 178.599 74.156C178.704 73.8059 178.896 73.498 179.175 73.2323C179.446 72.9744 179.754 72.8057 180.101 72.7261C180.464 72.6469 180.816 72.6556 181.158 72.7522C181.517 72.8492 181.825 73.0332 182.083 73.3041C182.341 73.575 182.505 73.8877 182.577 74.2421C182.664 74.597 182.655 74.9495 182.551 75.2997C182.462 75.6502 182.282 75.9544 182.011 76.2122C181.724 76.4857 181.399 76.6621 181.036 76.7413C180.681 76.8127 180.329 76.796 179.979 76.6912C179.645 76.5867 179.353 76.4031 179.103 76.1404ZM179.558 75.7068C179.855 76.0187 180.196 76.1714 180.581 76.1649C180.974 76.1506 181.306 76.0145 181.577 75.7566C181.856 75.4909 182.001 75.1578 182.011 74.7573C182.021 74.3567 181.893 74.0168 181.627 73.7377C181.361 73.4586 181.028 73.3141 180.628 73.3042C180.227 73.2943 179.887 73.4222 179.608 73.6879C179.321 73.9614 179.168 74.2943 179.151 74.6867C179.141 75.0712 179.277 75.4113 179.558 75.7068Z" fill="white"/>
                            <defs>
                                <linearGradient id="paint0_linear_118_429" x1="114.792" y1="117.979" x2="170.434"
                                                y2="173.441" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#006793"/>
                                    <stop offset="1" stopColor="#2C9FB7" stopOpacity="0"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_118_429" x1="125.811" y1="101.513" x2="125.811"
                                                y2="147.739" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white"/>
                                    <stop offset="1" stopColor="#A3A3A3"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_118_429" x1="125.811" y1="99.792" x2="125.811"
                                                y2="145.073" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white"/>
                                    <stop offset="1" stopColor="#E6E6E6"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear_118_429" x1="125.81" y1="153.17" x2="125.81"
                                                y2="111.512" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#C97A22"/>
                                    <stop offset="1" stopColor="#C96425"/>
                                </linearGradient>
                                <linearGradient id="paint4_linear_118_429" x1="125.81" y1="150.613" x2="125.81"
                                                y2="108.955" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FFC847"/>
                                    <stop offset="1" stopColor="#FFA24A"/>
                                </linearGradient>
                                <linearGradient id="paint5_linear_118_429" x1="126.237" y1="149.775" x2="126.237"
                                                y2="109.855" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#F7BA2A"/>
                                    <stop offset="1" stopColor="#F78F2D"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </a>
                </div>
                <img className={'animated-image levitate'} src={object} alt={'animated'}/>
            </div>
        </div>
    );
}

export default AnimatedImage;
