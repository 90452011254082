import React, {createRef, useEffect, useState} from 'react';
import './Slider.scss';

interface ISliderProps {
    items: any[],
    itemRender: any, //if used with loop=true - need set min-width (may lag if a component does not immediately reach its normal width)
    reverseDirection?: boolean,
    itemWidth: number;
}

function Slider(props: ISliderProps) {
    const ms = 500;
    const [RenderComponent] = useState<any>(() => React.memo(props.itemRender, (prevProps: any, nextProps: any) => {
        Object.keys(prevProps).forEach((key: string) => {
            if(prevProps[key] !== nextProps[key]) return false;
        });
        return true;
    } ));
    const rootRef = createRef<HTMLDivElement>();
    const [rootWidth, setRootWidth] = useState(0);
    const trackRef = createRef<HTMLDivElement>();
    const [renderedElements, setRenderedElements] = useState<any[]>([]);
    const [leftElementIndex, setLeftElementIndex] = useState(0);
    const [rightElementIndex, setRightElementIndex] = useState(0);
    const [isMove, setIsMove] = useState(false);

    const [mousePressedX, setMousePressedX] = useState(0);
    const [mousePressed, setMousePressed] = useState(0); //0 - init, 1 - false, 2 - true
    const [mouseMove, setMouseMove] = useState(0);
    
    const [moveDir, setMoveDir] = useState('left'); 
    const [needLoop, setNeedLoop] = useState(true);
    const [showElementsCount, setShowElementsCount] = useState(0);

    const [activeElementsArray, setActiveElementsArray] = useState<boolean[]>([]);
    const [translateToElement, setTranslateToElement] = useState(0);

    const getNextElementId = (elementId: number) => {
        return elementId < props.items.length - 1 ? elementId + 1 : 0;
    }
    const getPrevElementId = (elementId: number) => {
        return elementId > 0 ? elementId - 1 : props.items.length - 1;
    }
    const htmlRect = (element: any) => {
        return element.current?.getBoundingClientRect() ?? {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0};
    }
    
    const recountActiveElements = () => {
        setActiveElementsArray((old) => {
            const newArr = props.items.map(() => false);
            let id = getNextElementId(leftElementIndex);
            let showI = showElementsCount - 1;
            for(; showI >= 0; showI--){
                newArr[id] = true; 
                id = getNextElementId(id);
            }
            return newArr;
        });
    }

    useEffect(() => {
        let showItemsCount = Math.floor(htmlRect(rootRef).width / props.itemWidth);
        if(showItemsCount >= props.items.length) {
            showItemsCount = props.items.length;
            setNeedLoop(false);
        }
        const width = showItemsCount * props.itemWidth;
        setRootWidth(width);
        setShowElementsCount(showItemsCount);
        
        const showItems = [];
        const needItems = Math.ceil(document.body.offsetWidth / props.itemWidth) + 1;
        let id = props.items.length-1;
        for(let i = -1; i < needItems-1; i++){
            const itemProps = {_keyIndex: i, _id: id, ...props.items[id]};
            showItems.push(itemProps);
            id = getNextElementId(id);
        }
        setRightElementIndex(getPrevElementId(id));
        setLeftElementIndex(props.items.length-1);
        setRenderedElements(showItems);
        setIsMove(false);
        recountActiveElements();
    },[]);
    
    useEffect(() => {
        recountActiveElements();
    }, [leftElementIndex]);

    useEffect(() => {
        const onMouseMove = function(e: any){
            if(mousePressed === 2) {
                setMouseMove(e.pageX - mousePressedX);
            }
        }
        const onTouchMove = function (e: any){
            onMouseMove(e.targetTouches[0]);
        }
        const onMouseUp = function(e: any){
            if(mousePressed === 2){
                setMousePressed(1);
            }
        }
        const onTouchUp = function (e: any){
            onMouseUp(e.targetTouches[0]);
        }

        document.body.addEventListener('mouseup', onMouseUp);
        document.body.addEventListener('touchend', onTouchUp);

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('touchmove', onTouchMove);

        return () => {
            document.body.removeEventListener('mouseup', onMouseUp);
            document.body.removeEventListener('touchend', onTouchUp);

            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('touchmove', onTouchMove);
        }
    }, [mousePressed])

    useEffect(() => {
        if(mousePressed === 1){
            onMouseUp();
        }
    }, [mousePressed])

    const onMouseDown = (e: any) => {
        setMousePressed(2);
        setMousePressedX(e.pageX);
    }

    const onMouseUp = function (){
        if(!needLoop || isMove) return;
        if(mouseMove > 1){
            setMoveDir('left');
            setIsMove(true);
        }
        else if(mouseMove < -1){
            setMoveDir('right');
            setIsMove(true);
        }
    }
    
    useEffect(() => {
        if(isMove && translateToElement === 0){
            setTimeout(() => {
                setIsMove(false);
                if(moveDir === 'left'){
                    setRenderedElements((old) => {
                        const itemProps = {_keyIndex: old[0]._keyIndex - 1,  _id: getPrevElementId(leftElementIndex), ...props.items[getPrevElementId(leftElementIndex)]};
                        const newArr = [itemProps, ...old];
                        newArr.pop();
                        return newArr;
                    });
                    setLeftElementIndex((old) => getPrevElementId(old));
                    setRightElementIndex((old) => getPrevElementId(old));
                }
                else{
                    setRenderedElements((old) => {
                        const itemProps = {_keyIndex: old[old.length-1]._keyIndex + 1, _id: getNextElementId(rightElementIndex), ...props.items[getNextElementId(rightElementIndex)]};
                        const newArr = [...old, itemProps];
                        newArr.shift();
                        return newArr;
                    });
                    setLeftElementIndex((old) => getNextElementId(old));
                    setRightElementIndex((old) => getNextElementId(old));
                }
            }, ms)
        }
    }, [isMove]);
    
    const onDotClick = (index: number) => {
        if(activeElementsArray[index]) return;

        //get position (how much need scroll to left/right)
        let moveTo = 0;
        let prev = getPrevElementId(index);
        let next = getNextElementId(index);
        for(let i = 0; i < props.items.length-1; i++){
            if(activeElementsArray[next]){
                moveTo = - i - 1;
                break;
            }
            if(activeElementsArray[prev]){
                moveTo = i + 1;
                break;
            }
            prev = getPrevElementId(prev);
            next = getNextElementId(next);
        }
        //startCustomMove() // add elements to side
        setTranslateToElement(-props.itemWidth * moveTo);
        
        if(moveTo < 0){
            setTimeout(() => setIsMove(true), 0);
            setRenderedElements((old) => {
                let arr = [...old];
                let prev = getPrevElementId(leftElementIndex);
                for(let i = Math.abs(moveTo); i > 0; i--){
                    const itemProps = {_keyIndex: arr[0]._keyIndex - 1,  _id: prev, ...props.items[prev]};
                    arr = [itemProps, ...arr];
                    prev = getPrevElementId(prev);
                }
                return arr;
            });
        }
        else{
            setIsMove(true);
            setRenderedElements((old) => {
                let arr = [...old];
                let next = getNextElementId(rightElementIndex);
                for(let i = Math.abs(moveTo); i > 0; i--){
                    const itemProps = {_keyIndex: arr[arr.length-1]._keyIndex + 1, _id: next, ...props.items[next]};
                    arr = [...arr, itemProps];
                    next = getNextElementId(next);
                }
                return arr;
            });
        }
        setTimeout(() => {
            setIsMove(false);
            setTranslateToElement(0);
            if(moveTo < 0){
                setRenderedElements((old) => {
                    let arr = [...old];
                    let rightCurr = rightElementIndex;
                    let leftCurr = leftElementIndex;
                    for(let i = Math.abs(moveTo); i > 0; i--){
                        arr.pop();
                        rightCurr = getPrevElementId(rightCurr);
                        leftCurr = getPrevElementId(leftCurr);
                    }
                    setLeftElementIndex(leftCurr);
                    setRightElementIndex(rightCurr);
                    return arr;
                });
            }
            else{
                setRenderedElements((old) => {
                    let arr = [...old];
                    let rightCurr = rightElementIndex;
                    let leftCurr = leftElementIndex;
                    for(let i = Math.abs(moveTo); i > 0; i--){
                        arr.shift();
                        rightCurr = getNextElementId(rightCurr);
                        leftCurr = getNextElementId(leftCurr);
                    }
                    setLeftElementIndex(leftCurr);
                    setRightElementIndex(rightCurr);
                    return arr;
                });
            }
        }, ms)
        //end CustomMove() // remove elements from another side
        
    }

    return (
        <div ref={rootRef} className="Slider"
             style={{
                 width: rootWidth > 0 ? rootWidth+'px' : '100%',
             }}
        >
            <div
                ref={trackRef}
                className={'carousel-track'}
                onMouseDown={onMouseDown}
                onTouchStart={(e) => onMouseDown(e.targetTouches[0])}
                style={{
                    transform: isMove 
                        ? translateToElement !== 0
                            ? `translate3d(${(translateToElement > 0 ? 0 : translateToElement) - props.itemWidth}px, 0, 0)`
                            : `translate3d(${moveDir === 'right' ? -props.itemWidth*2 : 0}px, 0, 0)` 
                        : translateToElement !== 0 
                            ? `translate3d(${-translateToElement - props.itemWidth}px, 0, 0)`
                            : `translate3d(${-props.itemWidth}px, 0, 0)`,
                    transition: isMove ? `all ${ms-50}ms cubic-bezier(.46,.01,.51,.87)` : '',
                    cursor: needLoop ? (mousePressed === 2 ? 'grabbing' : 'pointer') : '',
                }}
            >
                {renderedElements.map((element: any) => (
                    <RenderComponent key={element._keyIndex} {...element}/>
                ))}
            </div>
            {needLoop &&
                <div className={'dots'}>
                    {activeElementsArray.map((isActive, index) => (
                        <div key={index} className={`dot ${isActive ? 'active' : ''}`} onClick={() => onDotClick(index)}/>
                    ))}
                </div>
            }
            
        </div>
    );
}

export default Slider;