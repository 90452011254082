import React, {useState} from 'react';
import './ChatWithUs.scss';
import roundedText from './rouded-text.svg';

function ChatWithUs() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="ChatWithUs">
            <div className={'container'+(isOpen ? ' opened' : '')}>
                <div className={'menu-button'} onClick={() => {setIsOpen(!isOpen)}} />
                <img className={'rounded-text'} src={roundedText} alt={'animated'}/>
                <a className={'menu-item wa'}
                   target="_blank"
                   rel="noreferrer" 
                   href="https://wa.me/972546655677?text=Hello, Dan!"/>
                <a className={'menu-item telegram'} 
                   target="_blank"
                   rel="noreferrer" 
                   href="https://telegram.me/The7thsky2021"/>
            </div>
        </div>
    );
}

export default ChatWithUs;
