import React, {useState, useEffect }from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import './Comics.scss';
import comicsData from "./ComicsData";
import clouds from "../resources/comics/clouds.svg";
import smallCloud from "../resources/comics/small-cloud.svg";

import Rocket from "./Rocket";

import Razor from '../resources/comics/razor-crest.png';
import BlackHole from "../resources/comics/black-hole.webp";

import Header from "./Header/Header";
import Preview from "./Preview/Preview";
import ComicModal from "./ComicModal/ComicModal";

function Comics() {
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [currentChapter, setCurrentChapter] = useState<any>(null);

    const onClick = (id: string) => {
        setIsOpenModal(true);
        setCurrentChapter(comicsData[Number(id)].comic);
    }

    const handleScroll = () => {
            for(let item of Array.from(document.querySelectorAll('.preview'))) {
               if(!isElementXPercentInViewport(item))
                   continue
               let itemHtmlElement = item as HTMLElement;
               let index = itemHtmlElement.dataset.id
               if(index !== undefined){
                    activeLinkControl(Number(index))
               }
               break;
           }
    };

    const isElementXPercentInViewport = function(el: Element) {
        let rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < 50 ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < 50
        )
    };

    const activeLinkControl = (index: Number) => {
            document.querySelector('.Sidenav__item.active')!.classList.remove('active')

            let navItem = document.querySelector('.Sidenav__item[data-id="'+index+'"]')
            if(navItem) navItem.classList.add('active')
    }

    const scrollToChapter = (index: Number) => {
        activeLinkControl(index)

        let chapter =  document.querySelector('.preview[data-id="'+index+'"]') as HTMLElement
        if(chapter) {
            window.scrollTo(0, chapter.offsetTop -  (window.innerWidth < 2600 ? 80 : 500))
        }
    }

    useEffect(() => {
        let navItem = document.querySelector('.Sidenav__item[data-id="0"]')
        if(navItem) navItem.classList.add('active')

          window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <ParallaxProvider>
        <div className={'comics'}>
            <Parallax
                style={{height: '100px', pointerEvents: 'none', width: '100%', position: 'absolute', zIndex: 5}}
                translateX={[85, 70]}
                translateY={[100, 123]}>
                <img className={'razor'} src={Razor} alt="ship"/>
            </Parallax>
            <Parallax  style={{ pointerEvents: 'none',
                top:'-3%', right: '-10%', width: '45%', position: 'absolute', zIndex: 4}}
                       translateY={[50, -50]}>
                <img className={'blackHole'} src={BlackHole} alt="black-hole"/>
            </Parallax>
            <Header showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu}/>
            <nav className={'Sidenav'}>
                {comicsData.map((comic, index) => (
                    <div
                        key={index}
                         className={'Sidenav__item'}
                         data-id={index}
                         onClick={() => scrollToChapter(index)}>
                        <span className={'chapter'}>Chapter {index+1}</span>
                        <div className={'dot'}>
                            <span></span>
                        </div>
                    </div>
                ))}
                <img src={smallCloud} alt={'small-clod'} />
            </nav>
            <main className={'previews'}>
                {comicsData.map((comic) => (
                    <Preview key={comic.id}
                             id={comic.id}
                             data-id={comic.id}
                             preview={ comic.preview }
                             shadowType={ comic.shadowType }
                             foreground={ comic.foreground }
                             bgCoordinates={ comic.bgCoordinates }
                             shadowCoordinates={ comic.shadowCoordinates }
                             onElementClick={onClick}
                    />
                ))}
            </main>
            {
                window.innerWidth > 1700 && <Rocket />
            }
            
            <img src={clouds} alt={'clouds'} className={'clouds'} />
        </div>
        <ComicModal
            isOpen={isOpenModal}
            closeModal={() => setIsOpenModal(false)}
            chapter={currentChapter}
        />
        </ParallaxProvider>
    );
}

export default Comics;