import React from 'react';
import './SideMenuContent.scss'

interface ISideMenuContentProps {
    hideMenu: Function,
}

function SideMenuContent(props: ISideMenuContentProps) {
    
    return (
        <nav className={'navigation'}>
            <div className="nav-links">
                <a href="#who-do-we-serve" className="link" onClick={() => props.hideMenu()}>Who do We Serve</a>
                <a href="#who-we-are" className="link" onClick={() => props.hideMenu()}>Who We are</a>
                <a href="#completed-projects" className="link" onClick={() => props.hideMenu()}>Completed Projects</a>
                <a href="#our-team" className="link" onClick={() => props.hideMenu()}>Our Team</a>
                <a href="#contact-us" className="link" onClick={() => props.hideMenu()}>Let’s get in Touch</a>
            </div>
        </nav>
    );
}

export default SideMenuContent;
