import React, {useState} from 'react';
import './OurContacts.scss';
import Input from "../../helpers/Input/Input";
import Textarea from "../../helpers/Textarea/Textarea";
import backBalls from "./backBalls.png";
import frontBalls from "./frontBalls.png";
import SideMenuFooter from "../SideMenu/SideMenuFooter/SideMenuFooter";
import Alert from "./Alert/Alert";
import ChatWithUs from "./ChatWithUs/ChatWithUs";

function OurContacts() {
    const [showRequired, setShowRequired] = useState(false);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const initialFields = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
        terms: false,
    }
    const [fields, setFields] = useState(initialFields);
    
    const setField = (value: any, fieldName: string) => {
        setFields((old) => {
            const newObj = {...old};
            // @ts-ignore
            newObj[fieldName] = value;
            return newObj;
        });
    }
    
    const checkFields = () => {
        if(!fields.firstname) return false;
        if(!fields.lastname) return false;
        if(!fields.email) return false;
        if(!fields.phone) return false;
        if(!fields.message) return false;
        if(!fields.terms) return false;
        
        return true;
    }
    
    const submitForm = (e: any) => {
        setShowRequired(true);
        if(!checkFields()) e.preventDefault();
        else{
            fetch(window.location.origin+'/emailHandler.php',{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: "POST",
                body: JSON.stringify(fields)
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if(data.success){
                    setFields(initialFields);
                    setShowRequired(false);
                    setIsOpenAlert(true);
                }
            });
            
            e.preventDefault();
        }
    }

    return (
        <div id={'contact-us'}  className="OurContacts">
            <div className="content modal-bg">
                <img className={'animated-image-back levitate'} src={backBalls} alt={'animated'}/>
                <img className={'animated-image-front levitate'} src={frontBalls} alt={'animated'}/>
                <div className={'content-form'}>
                    <div className={'title'}>Let’s get in Touch</div>
                    <div className={'underTitleText'}>
                        To learn how Tahion.pro can transform your business, schedule a free consultation today!
                    </div>
                    <form action="emailHandler.php" className={'contactForm'} onSubmit={submitForm} method={'POST'}>
                        <div className={'inputFields'}>
                            <Input required={showRequired && !fields.firstname} placeholder={'Firstname'} value={fields.firstname} onChange={(e: any) => setField(e.target.value, 'firstname')} />
                            <Input required={showRequired && !fields.lastname} placeholder={'Lastname'} value={fields.lastname} onChange={(e: any) => setField(e.target.value, 'lastname')} />
                        </div>
                        <div className={'inputFields'}>
                            <Input required={showRequired && !fields.email} placeholder={'E-mail'} value={fields.email} onChange={(e: any) => setField(e.target.value, 'email')} />
                            <Input required={showRequired && !fields.phone} placeholder={'Phone number'} value={fields.phone} onChange={(e: any) => setField(e.target.value, 'phone')} />
                        </div>
                        <div className={'inputFields'}>
                            <Textarea required={showRequired && !fields.message} placeholder={'Your Message'} value={fields.message} onChange={(e: any) => setField(e.target.value, 'message')}/>
                        </div>
                        {/*<label className={'inputFields forCheckbox'}>
                            <input id={'agree-checkbox'} required={showRequired  && !fields.terms} type={"checkbox"} checked={fields.terms} onChange={(e: any) => setField(e.target.checked, 'terms')}/>
                            <div>
                                I agree with&nbsp;
                                <a href={'#'} target="_blank" rel="noreferrer">Terms of Service</a>
                                &nbsp;and&nbsp;
                                <a href={'#'} target="_blank" rel="noreferrer">Privacy Policy</a>
                            </div>
                        </label> */}
                        <button type={'submit'}>Send Message</button>
                    </form>
                </div>
                <SideMenuFooter footer={"main"} />
                <div className={'all-right'}>
                    <span>© Tahion.pro 2022</span>
                    <span>All the rights reserved.</span>
                </div>
            </div>
            <Alert isOpen={isOpenAlert} closeAlert={() => setIsOpenAlert(false)}/>
            <ChatWithUs/>
        </div>
    );
}

export default OurContacts;
