import PinkMacaroon from "./pink_macaroon.png";
import PurpleMacaroon from "./purple_macaroon.png";

import './Cookies.scss';
import {useEffect, useState} from "react";


function Cookies() {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let cookies = document.cookie.split('; ')
        let tahionCookie: string | undefined = ''
        if(cookies.length > 0){
            tahionCookie = cookies.find((item) => item.indexOf('tahion_consent_cookies') != -1)
        }

        if(!Boolean(tahionCookie?.split('=')[1])){
            setIsActive(true)
        }
    }, [])

    const setCookieConsent = (value: boolean) => {
        document.cookie = 'tahion_consent_cookies='+value+'; max-age='+(60*60*24*30);
        setIsActive(false)
    }

    return (
        <div className={'Cookies-modal '+ (isActive ? 'active' : '' )}>
            <div>
                <img src={PurpleMacaroon} alt={''} className={'Macaroon purple'}/>
                <img src={PinkMacaroon} alt={''} className={'Macaroon pink'}/>
            </div>
            <div className={'Cookies-content'}>
                <p>We use cookies to ensure you get <br />the best experience on our website :)</p>
                <button id={'acceptCookies'} onClick={() => {
                    setCookieConsent(true)
                }}>
                    <span>
                        I Like Cookies
                    </span>
                </button>
            </div>
            <button id={'closeCookies'} onClick={() => {
                setIsActive(false);
            }}>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.6431 30.6294L5.44346 7.42976C4.86136 6.84765 4.86411 5.90005 5.44959 5.31457C6.03508 4.72908 6.98268 4.72633 7.56478 5.30844L30.7644 28.5081C31.3465 29.0902 31.3438 30.0378 30.7583 30.6233C30.1728 31.2088 29.2252 31.2115 28.6431 30.6294Z" fill="white"/>
                    <path d="M30.7644 7.4609L7.56477 30.6605C6.98266 31.2426 6.03506 31.2399 5.44958 30.6544C4.86409 30.0689 4.86134 29.1213 5.44345 28.5392L28.6431 5.33958C29.2252 4.75747 30.1728 4.76022 30.7583 5.34571C31.3438 5.9312 31.3465 6.8788 30.7644 7.4609Z" fill="white"/>
                </svg>
            </button>
        </div>
    );
}

export default Cookies;