import React from 'react';
import './TextElement.scss';

interface ITextElementProps {
    blackTitle: string,
    gradientTitle: string,
    texts: string[],
}

function TextElement(props: ITextElementProps) {
    return (
        <div className="TextElement">
            <div className={'forTitle'}>
                <span className={'title'}>{props.blackTitle}</span>
                <span className={'title gradient'}>{props.gradientTitle}</span>
            </div>
            {props.texts.map((text, index)=>(
                <div key={index} className={'text'}>{text}</div>
            ))}
        </div>
    );
}

export default TextElement;
