import React from 'react';
import './Project.scss';

interface IProjectProps {
    id: number,
    screen: any,
    title: any,
    text: any,
}

function Project(props: IProjectProps) {
    
    return (
        <div className="Project" data-id={props.id}>
            <img src={props.screen} alt={''}/>
            {props.title}
            {props.text}
        </div>
    );
}

export default Project;
