import React, {useState} from 'react';
import './Portfolio.scss';
import Project from "./Project/Project";
import InfinityCarousel from "../../helpers/InfinityCarousel/InfinityCarousel";
import ModalComponent from "./ModalComponent/ModalComponent";
import {smallProjectList, projectListForModal} from './PortfolioData';

function Portfolio() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [currentProject, setCurrentProject] = useState<any>(null);
    
    const onClick = (id: string) => {
        setIsOpenModal(true);
        setCurrentProject(projectListForModal.find((e) => e.id === parseInt(id)));
    }

    return (
        <div className="Portfolio modal-bg">
            <div className="content">
                <div className={'titleKey'}>portfolio</div>
                <div className={'title'}>Completed Projects</div>
                <div className={'underTitleText'}>
                    <div>We create custom software solutions from the ground up, designed especially</div>
                    <div>to realize your ideas and serve the goals of your business.</div>
                </div>
            </div>
            <InfinityCarousel 
                items={smallProjectList} 
                itemRender={Project} 
                itemWidth={280} 
                loop
                onElementClick={onClick} 
            />
            <ModalComponent 
                isOpen={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
                project={currentProject}
            />
            <div id={'our-team'} />
        </div> 
    );
}

export default Portfolio;