import React, {createRef, useEffect, useState} from 'react';
import './InfinityCarouselStaticWidth.scss';

interface IInfinityCarouselStaticWidthProps {
    itemRender: any,
    itemWidth: number;
} 

function InfinityCarouselStaticWidth(props: IInfinityCarouselStaticWidthProps) {
    const [RenderComponent] = useState<any>(() => React.memo(props.itemRender, (prevProps: any, nextProps: any) => {
        Object.keys(prevProps).forEach((key: string) => {
            if(prevProps[key] !== nextProps[key]) return false;
        });
        return true;
    } ));
    const rootRef = createRef<HTMLDivElement>();
    const trackRef = createRef<HTMLDivElement>();
    const [lastTransformX, setLastTransformX] = useState(0);
    const [mousePressedX, setMousePressedX] = useState(0);
    const [mousePressed, setMousePressed] = useState(0); //0 - init, 1 - false, 2 - true
    const [mouseMove, setMouseMove] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    
    const htmlRect = (element: any) => {
        return element.current?.getBoundingClientRect() ?? {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0};
    }    
    
    useEffect(() => {
        const onMouseMove = function(e: any){
            if(mousePressed === 2) {
                setMouseMove(e.pageX - mousePressedX);
            }
        }
        const onTouchMove = function (e: any){
            onMouseMove(e.targetTouches[0]);
        }
        const onMouseUp = function(e: any){
            if(mousePressed === 2){
                setMousePressed(1);
            }
        }
        const onTouchUp = function (e: any){
            onMouseUp(e.targetTouches[0]);
        }

        document.body.addEventListener('mouseup', onMouseUp);
        document.body.addEventListener('touchend', onTouchUp);

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('touchmove', onTouchMove);
        
        return () => {
            document.body.removeEventListener('mouseup', onMouseUp);
            document.body.removeEventListener('touchend', onTouchUp);
            
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('touchmove', onTouchMove);
        }
    }, [mousePressed])
    
    useEffect(() => {
        onMouseMove();
    }, [mouseMove])

    useEffect(() => {
        if(mousePressed === 1){
            onMouseUp();
        }
    }, [mousePressed])
    
    const onMouseDown = (e: any) => {
        setMousePressed(2); 
        setMousePressedX(e.pageX);
        setCurrentTranslate(lastTransformX+1);
    }

    const onMouseUp = function (){
        if(htmlRect(trackRef).left > htmlRect(rootRef).left ){
            console.log(1);
            setLastTransformX(0);
            setCurrentTranslate(0);
        }
        else if(props.itemWidth + htmlRect(trackRef).left < htmlRect(rootRef).width){
            setLastTransformX(-(props.itemWidth - htmlRect(rootRef).width));
            setCurrentTranslate(-(props.itemWidth - htmlRect(rootRef).width));
        }
        else{
            setLastTransformX(currentTranslate);
        }
    }

    const onMouseMove = function(){
        if(mousePressed === 2) {
            setCurrentTranslate(lastTransformX + mouseMove);
        }
    }
    
    return (
        <div ref={rootRef} className="InfinityCarouselStaticWidth"
             onMouseDown={onMouseDown}
             onTouchStart={(e) => onMouseDown(e.targetTouches[0])}
             style={{
                 cursor: mousePressed === 2 ? 'grabbing' : 'pointer',
             }}
        >
            <div 
                ref={trackRef}
                className={'carousel-track'}
                style={{
                    width: props.itemWidth,
                    transform: `translate3d(${currentTranslate}px, 0, 0)`,
                    transition: mousePressed === 2 ? '' : 'all 1000ms cubic-bezier(.46,.01,.51,.87)',
                }}
            >
                <RenderComponent />
            </div>
        </div>
    );
}

export default InfinityCarouselStaticWidth;
