import React from 'react';
import './FirstScreen.scss';
import AnimatedImage from "./AnimatedImage/AnimatedImage";
import EmLogo from  "../../resources/el-logo.svg";

function FirstScreen() {
    return (
        <div className="FirstScreen modal-bg">
            <div className="content">
                <div className={'mainText'}>
                    <div id={'turn-key-services'} />
                    <div className={'titleKey'}>turn-key services</div>
                    <div className={'title'}>Custom Software</div>
                    <div className={'title gradient'}>Development Company</div>
                    <div className={'aboutUs'}>We specialize in customized complex software development solutions and offer the comprehensive range of professional services, including business analysis and product design.</div>
                    <a href="https://emotionlogic.ai/partner-with-us/" className={'em-logo'} target={'_blank'}>
                        <img src={EmLogo} alt={'Emotion Login Trusted Development Partner'} />
                    </a>
                </div>
                <AnimatedImage />
                <div id={'who-do-we-serve'} />
            </div>
        </div>
    );
}

export default FirstScreen;
