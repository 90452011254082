import React, {createRef, useEffect, useState} from 'react';
import './AutoMoveCarousel.scss';

interface IAutoMoveCarouselProps {
    items: any[],
    itemRender: any, //if used with loop=true - need set min-width (may lag if a component does not immediately reach its normal width)
    reverseDirection?: boolean,
    itemWidth: number;
} 

function AutoMoveCarousel(props: IAutoMoveCarouselProps) {
    const moveToPX = 2;
    const [RenderComponent] = useState<any>(() => React.memo(props.itemRender, (prevProps: any, nextProps: any) => {
        Object.keys(prevProps).forEach((key: string) => {
            if(prevProps[key] !== nextProps[key]) return false;
        });
        return true;
    } ));
    const rootRef = createRef<HTMLDivElement>(); 
    const trackRef = createRef<HTMLDivElement>(); 
    const [showedElements, setShowedElements] = useState<any[]>([]);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [leftElementIndex, setLeftElementIndex] = useState(0);
    const [rightElementIndex, setRightElementIndex] = useState(0);
    
    
    const getNextElementId = (elementId: number) => {
        return elementId < props.items.length - 1 ? elementId + 1 : 0;
    }
    const getPrevElementId = (elementId: number) => {
        return elementId > 0 ? elementId - 1 : props.items.length - 1;
    }
    const htmlRect = (element: any) => {
        return element.current?.getBoundingClientRect() ?? {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0};
    }

    useEffect(() => {
        const showItems = [];
        const needItems = Math.ceil(document.body.offsetWidth / props.itemWidth) + 3;
        let id = 0;
        for(let i = 0; i < needItems; i++){
            const itemProps = {_keyIndex: i, ...props.items[id]};
            showItems.push(itemProps);
            id = getNextElementId(id);
        }
        setRightElementIndex(id);
        setShowedElements(showItems);
    },[]);
    
    useEffect( () => {
        const interval = setInterval( () => {
            setCurrentTranslate(old => {return old + moveToPX});
        }, 10);
        return () => {
            clearInterval(interval);
        }
    }, [])
    
    
    useEffect(() => {
        move();
    }, [currentTranslate]);
    
    const move = () => {
        if(!props.reverseDirection){
            if(htmlRect(trackRef).left > htmlRect(rootRef).left){
                setShowedElements((old) => {
                    const itemProps = {_keyIndex: old[0]._keyIndex - 1, ...props.items[getPrevElementId(leftElementIndex)]};
                    const newArr = [itemProps, ...old];
                    newArr.pop();
                    return newArr;
                });
                setLeftElementIndex((old) => getPrevElementId(old));
                setCurrentTranslate((old) =>  old - props.itemWidth);
                return;
            }
        }
        else{
            if((trackRef.current?.scrollWidth ?? 0) + htmlRect(trackRef).left < htmlRect(rootRef).right){
                setShowedElements((old) => {
                    const itemProps = {_keyIndex: old[old.length-1]._keyIndex + 1, ...props.items[getNextElementId(rightElementIndex)]};
                    const newArr = [...old, itemProps];
                    newArr.shift();
                    return newArr;
                });
                setRightElementIndex((old) => getNextElementId(old));
                setCurrentTranslate((old) => old + props.itemWidth);
                return;
            }
        }
    }
    
    return (
        <div ref={rootRef} className="AutoMoveCarousel">
            <div
                ref={trackRef}
                className={'carousel-track'}
                style={{
                    transform: `translate3d(${currentTranslate}px, 0, 0)`,
                }}
            >
                {showedElements.map((element: any) => (
                    <RenderComponent key={element._keyIndex} {...element}/>
                ))}
            </div>
        </div>
    );
}

export default AutoMoveCarousel;