import React from 'react';
import './Logo.scss';

interface ILogoProps {
    logo: any,
    alt: any,
}

function Logo(props: ILogoProps) {

    return (
        <div className={'Logo-container'}>
            <div className={'Logo'}>
                <img src={props.logo} alt={props.alt}/>
            </div>
        </div>
    );
}

export default Logo;
