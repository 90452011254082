import React, {useEffect} from 'react';
import './SideMenu.scss'
import SideMenuContent from "./SideMenuContent/SideMenuContent";
import SideMenuFooter from "./SideMenuFooter/SideMenuFooter";

interface ISideMenuProps {
    showSideMenu: boolean,
    setShowSideMenu: Function,
}

function SideMenu(props: ISideMenuProps) {
    const rootRef = React.useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if(props.showSideMenu){
            rootRef.current?.classList.add('open');
        }
        else{
            rootRef.current?.classList.remove('open');
        }
    }, [props.showSideMenu])
    
    return (
        <>
            <div className={'SideMenu'} ref={rootRef}>
                <div className={'grogu'}/>
                <SideMenuContent hideMenu={() => props.setShowSideMenu(false)} />
                <SideMenuFooter footer={"side-menu"} />
            </div>
            <div className={'closeMenu'} onClick={() => props.setShowSideMenu(false)}/>
        </>
    );
}

export default SideMenu;
