import React, {createRef, useEffect, useState} from 'react';
import './TeamMember.scss';
import {Keyframes} from "../../../../helpers/Keyframe/Keyframe";

interface ITeamMemberProps {
    avatar: any,
    name: string,
    jobCountry?: string,
    centerPos: number,
    secondsForRound: number,
    radius: number,
    startDegree: number,
    reverseWay?: boolean,
    imgSize: number,
    imgHeight?: number,
    noMove?: boolean,
    noTooltip?: boolean
}

function TeamMember(props: ITeamMemberProps) {
    const [keyframeName] = useState(() => props.name.split(' ').join('_'));
    const [tooltipML, setTooltipML] = useState(0);
    const tooltipRef = createRef<HTMLDivElement>();
    
    useEffect(() => {
        setTooltipML(-(tooltipRef.current?.getBoundingClientRect().width || 0)/2);
    }, [tooltipRef.current])
    
    return (
        <div className={"TeamMember"} 
             style={{
                 top: props.centerPos - (props.imgHeight ? props.imgHeight/2 : props.imgSize/2),  
                 left: props.centerPos - props.imgSize/2,
                 animation: props.noMove ? '' : `${keyframeName} ${props.secondsForRound}s infinite linear`
             }}
        >
            <Keyframes name={keyframeName}
                   from={{ transform: `rotate(${270 + props.startDegree + (props.reverseWay ? 360 : 0)}deg) translate(${props.radius}px) rotate(-${270 + props.startDegree + (props.reverseWay ? 360 : 0)}deg)` }}
                   to={{ transform: `rotate(${270 + props.startDegree + (props.reverseWay ? 0 : 360)}deg) translate(${props.radius}px) rotate(-${270 + props.startDegree + (props.reverseWay ? 0 : 360)}deg)` }}
            />
            
            <img src={props.avatar} alt={'avatar'} style={{width: props.imgSize,}} />
            {!props.noTooltip 
                ? <div ref={tooltipRef} className={'tooltip-text'} style={{marginLeft: tooltipML}}>
                    <div className={'name'}>{props.name}</div>
                    <div className={'job-country'}>{props.jobCountry}</div>
                </div>
                : false
            }
        </div>
    );
}

export default TeamMember;