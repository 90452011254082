import React, {createRef, CSSProperties, useEffect, useState} from 'react';
import './Services.scss';
import TextElement from "./TextElement/TextElement";
import custom_software from "./custom_software.png";
import product from "./product.png";
import business from "./business.png";
import {usePageScrolling} from "../../hooks/usePageScrolling";

function Services() {
    const rootRef = createRef<HTMLDivElement>();
    const scroll = usePageScrolling();
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        if(!isShow && ((rootRef.current?.getBoundingClientRect().top  || 0) +(window.innerHeight)) < scroll) setIsShow(true);
    }, [scroll, rootRef.current]);
    
    const textElements = [
        {
            blackTitle: 'Custom Software',
            gradientTitle: 'Development',
            texts:[
                'We create custom software solutions, everything from apps to the most complex of systems, from the ground up. Our experts will delve into your industry and work closely with you to realize your vision and to design an exceptional product that will help you attain your business goals.'
            ],
            style: {
                marginTop: '30px',
                opacity: isShow ? 1 : 0,
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 500ms',
            } as CSSProperties,
        },{
            blackTitle: 'Product',
            gradientTitle: 'Development',
            texts:[
                'We can handle product building A-Z, our teams have a proven ability to apply their expertise in tackling challenging projects and are experienced in collaborating in the creation of cohesive, user-friendly, top-quality products.',
                'We\'ve successfully created, engineered, and delivered software solutions to companied worldwide, including some engaged in the most emerging industries, and our customers get the best results every time.'
            ],
            style: {
                opacity: isShow ? 1 : 0,
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 1500ms',
            } as CSSProperties,
        },{
            blackTitle: 'Business',
            gradientTitle: 'Analysis',
            texts:[
                'Business analyses serve to outline a project\'s path. Our business analysis specialists will analyze your project\'s functionality, business logic, software architecture, and future usage scenarios, map your needs and plan your project accordingly.',
                'Consequently, the solution created will enable streamlining and savings, and you will join our many clients who are already reaping the benefits of having highly successful software systems that cater perfectly to your customers\' needs.'
            ],
            style: {
                opacity: isShow ? 1 : 0,
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 2500ms',
            } as CSSProperties,
        },
    ];

    return (
        <>
            <div className="Services-title modal-bg">
                <div className={'titleKey'}>services</div>
            </div>
            <div ref={rootRef} className="Services">
                <div className="content modal-bg">
                    <div className={'row'} style={textElements[0].style}>
                        <TextElement blackTitle={textElements[0].blackTitle} gradientTitle={textElements[0].gradientTitle} texts={textElements[0].texts}/>
                        <img src={custom_software} alt={textElements[0].blackTitle}/>
                    </div>
                    <div className={'row'} style={textElements[1].style}>
                        <img src={product} alt={textElements[1].blackTitle}/>
                        <TextElement blackTitle={textElements[1].blackTitle} gradientTitle={textElements[1].gradientTitle} texts={textElements[1].texts}/>
                    </div>
                    <div className={'row last-service'} style={textElements[2].style}>
                        <TextElement blackTitle={textElements[2].blackTitle} gradientTitle={textElements[2].gradientTitle} texts={textElements[2].texts}/>
                        <img src={business} alt={textElements[2].blackTitle}/>
                    </div>
                </div>
            </div>
        </>
        
    );
}

export default Services;
