import React from 'react';
import './Map.scss';
import belarus from '../../../resources/country_flags/belarus.svg';
import canada from '../../../resources/country_flags/canada.svg';
import germany from '../../../resources/country_flags/germany.svg';
import israel from '../../../resources/country_flags/israel.svg';
import russia from '../../../resources/country_flags/russia.svg';
import unitedKingdom from '../../../resources/country_flags/united-kingdom.svg';
import unitedStates from '../../../resources/country_flags/united-states.svg';

function Map() {

    return (
        <div className="Map">
            <img className={'country belarus'} src={belarus} alt={'belarus'} />
            <img className={'country canada'} src={canada} alt={'canada'} />
            <img className={'country germany'} src={germany} alt={'germany'} />
            <img className={'country israel'} src={israel} alt={'israel'} />
            <img className={'country russia'} src={russia} alt={'russia'} />
            <img className={'country united-kingdom'} src={unitedKingdom} alt={'unitedKingdom'} />
            <img className={'country united-states'} src={unitedStates} alt={'unitedStates'} />
        </div>
    );
}

export default Map;
