import React, {CSSProperties} from 'react';
import './IndustriesItem.scss';

interface IIndustriesItemProps {
    id: number,
    icon: any,
    title: string,
    shortText: string,
    text: string,
    onClick: Function,
    style?: CSSProperties
}

function IndustriesItem(props: IIndustriesItemProps) {
    const rootRef = React.createRef<HTMLDivElement>();

    return (
        <div ref={rootRef} className={'IndustriesItem'} style={props.style}>
            <div>
                <div className={'forTitle'}>
                    <img src={props.icon} alt={props.title}/>
                    <div>{props.title}</div>
                </div>
                <div className={'text'}>{props.shortText}</div>
            </div>
            <svg className={'arrow'} onClick={() => props.onClick(props.id)} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.44253 12.6848C1.54074 12.7007 1.64012 12.7081 1.73955 12.7068L18.6711 12.7068L18.3019 12.8785C17.941 13.0493 17.6127 13.2818 17.3317 13.5654L12.5836 18.3134C11.9583 18.9103 11.8532 19.8706 12.3347 20.5887C12.8949 21.3538 13.9694 21.52 14.7346 20.9597C14.7965 20.9144 14.8552 20.865 14.9104 20.8119L23.4964 12.226C24.1674 11.5557 24.1679 10.4685 23.4977 9.79749C23.4973 9.79706 23.4968 9.79658 23.4964 9.79615L14.9104 1.21021C14.2389 0.540563 13.1517 0.542064 12.482 1.21359C12.4293 1.26645 12.3801 1.32269 12.3347 1.38193C11.8533 2.09998 11.9583 3.06027 12.5836 3.6572L17.3231 8.41381C17.575 8.66602 17.8647 8.87751 18.1817 9.04059L18.6968 9.27241L1.83405 9.2724C0.956838 9.23983 0.187217 9.8526 0.0224199 10.7148C-0.12939 11.651 0.5064 12.5329 1.44253 12.6848Z" fill="#546E7A"/></svg>
        </div>
    );
}

export default IndustriesItem;
