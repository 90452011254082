import React from 'react';
import './OurIndustries.scss';
import IndustriesList from "./IndustriesList/IndustriesList";
import InfinityCarouselStaticWidth from "../../helpers/InfinityCarouselStaticWidth/InfinityCarouselStaticWidth";

function OurIndustries() {

    return (
        <div className="OurIndustries modal-bg">
            <div className="content">
                <div className={'titleKey'}>our industries</div>
            </div>
            {window.innerWidth > 1440 
                ? <IndustriesList/> 
                : <InfinityCarouselStaticWidth itemRender={IndustriesList} itemWidth={window.innerWidth > 768 ? 2215 : (window.innerWidth > 450 ? 2150 : 1740)} />
            }
            <div id={'completed-projects'} style={{
                position: 'relative',
                bottom: '100px'
            }}/>
        </div>
    );
}

export default OurIndustries;
