import React from 'react';
import './Testimonial.scss';

interface ITestimonialProps {
    firstLine: string,
    secondLine: string,
    text: string,
    avatar: any,
}

function Testimonial(props: ITestimonialProps) {

    return (
        <div className="Testimonial">
            <div className={'text'}>{props.text}</div>
            <div className={'user'}>
                <img src={props.avatar} alt={props.firstLine}/>
                <div className={'text'}>
                    <div>{props.firstLine}</div>
                    <div>{props.secondLine}</div>
                </div>
            </div>
        </div>
    );
} 

export default Testimonial;
