import React, {createRef, useEffect, useState} from 'react';
import './InfinityCarousel.scss';

interface IInfinityCarouselProps {
    items: any[],
    itemRender: any, //if used with loop=true - need set min-width (may lag if a component does not immediately reach its normal width)
    loop?: boolean,
    itemWidth: number;
    onElementClick?: Function; //for correct click on entire element need set pointer-events: none for child elements itemRender
} 

function InfinityCarousel(props: IInfinityCarouselProps) {
    const [RenderComponent] = useState<any>(() => React.memo(props.itemRender, (prevProps: any, nextProps: any) => {
        Object.keys(prevProps).forEach((key: string) => {
            if(prevProps[key] !== nextProps[key]) return false;
        });
        return true;
    } ));
    const rootRef = createRef<HTMLDivElement>();
    const trackRef = createRef<HTMLDivElement>();
    const [showedElements, setShowedElements] = useState<any[]>([]);
    const [lastTransformX, setLastTransformX] = useState(0);
    const [mousePressedX, setMousePressedX] = useState(0);
    const [mousePressed, setMousePressed] = useState(0); //0 - init, 1 - false, 2 - true
    const [mouseMove, setMouseMove] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [leftElementIndex, setLeftElementIndex] = useState(0);
    const [rightElementIndex, setRightElementIndex] = useState(0);
    const [isOnClick, setIsOnClick] = useState(false);
    
    const getNextElementId = (elementId: number) => {
        return elementId < props.items.length - 1 ? elementId + 1 : 0;
    }
    const getPrevElementId = (elementId: number) => {
        return elementId > 0 ? elementId - 1 : props.items.length - 1;
    }
    const htmlRect = (element: any) => {
        return element.current?.getBoundingClientRect() ?? {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0};
    }

    useEffect(() => {
        const showItems = [];
        const needItems = props.loop ? Math.ceil(document.body.offsetWidth / props.itemWidth) + 3 : props.items.length;
        let id = 0;
        for(let i = 0; i < needItems; i++){
            const itemProps = {_keyIndex: i, ...props.items[id]};
            showItems.push(itemProps);
            id = getNextElementId(id);
        }
        setRightElementIndex(id);
        setShowedElements(showItems);
    },[]);
    
    
    useEffect(() => {
        const onMouseMove = function(e: any){
            if(mousePressed === 2) {
                setMouseMove(e.pageX - mousePressedX);
            }
        }
        const onTouchMove = function (e: any){
            onMouseMove(e.targetTouches[0]);
        }
        const onMouseUp = function(e: any){
            if(mousePressed === 2){
                setMousePressed(1);
            }
        }
        const onTouchUp = function (e: any){
            onMouseUp(e.targetTouches[0]);
        }

        document.body.addEventListener('mouseup', onMouseUp);
        document.body.addEventListener('touchend', onTouchUp);

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('touchmove', onTouchMove);
        
        return () => {
            document.body.removeEventListener('mouseup', onMouseUp);
            document.body.removeEventListener('touchend', onTouchUp);
            
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('touchmove', onTouchMove);
        }
    }, [mousePressed])
    
    useEffect(() => {
        onMouseMove();
    }, [mouseMove])

    useEffect(() => {
        if(mousePressed === 1){
            onMouseUp();
        }
    }, [mousePressed])

    const onClick = (e: any) => {
        if(isOnClick && props.onElementClick) { 
            const id = e.target.getAttribute('data-id');
            if(typeof(id) !== "undefined" && id !== null){
                props.onElementClick(e.target.getAttribute('data-id'));
            }
        }
    }
    
    const onMouseDown = (e: any) => {
        setMousePressed(2); 
        setMousePressedX(e.pageX);
        setIsOnClick(true);
        setCurrentTranslate(lastTransformX);
    }

    const onMouseUp = function (){
        if(!props.loop){
            if(htmlRect(trackRef).left > htmlRect(rootRef).left ){
                setLastTransformX(0);
                setCurrentTranslate(0);
            }
            else if(document.body.offsetWidth - htmlRect(trackRef).left > htmlRect(rootRef).right){
                setLastTransformX(-(htmlRect(rootRef).right - document.body.offsetWidth));
                setCurrentTranslate(-(htmlRect(rootRef).right - document.body.offsetWidth));
            }
            else{
                setLastTransformX(currentTranslate);
            }
        }
        else{
            setLastTransformX(currentTranslate);
        }
    }

    const onMouseMove = function(){
        if(mousePressed === 2) {
            setIsOnClick(false);
            if(props.loop){
                if(htmlRect(trackRef).left > htmlRect(rootRef).left){
                    setShowedElements((old) => {
                        const itemProps = {_keyIndex: old[0]._keyIndex - 1, ...props.items[getPrevElementId(leftElementIndex)]};
                        const newArr = [itemProps, ...old];
                        newArr.pop();
                        return newArr;
                    });
                    setLeftElementIndex((old) => getPrevElementId(old));
                    setCurrentTranslate(lastTransformX - props.itemWidth + mouseMove);
                    setLastTransformX(lastTransformX - props.itemWidth);
                    return;
                }
                if((trackRef.current?.scrollWidth ?? 0) + htmlRect(trackRef).left < htmlRect(rootRef).right){
                    setShowedElements((old) => {
                        const itemProps = {_keyIndex: old[old.length-1]._keyIndex + 1, ...props.items[getNextElementId(rightElementIndex)]};
                        const newArr = [...old, itemProps];
                        newArr.shift();
                        return newArr;
                    });
                    setRightElementIndex((old) => getNextElementId(old));
                    setLastTransformX(lastTransformX + props.itemWidth);
                    setCurrentTranslate(lastTransformX + props.itemWidth + mouseMove);
                    return;
                }
            }
            setCurrentTranslate(lastTransformX + mouseMove);
        }
    }
    
    return (
        <div ref={rootRef} className="InfinityCarousel"
             onMouseDown={onMouseDown}
             onTouchStart={(e) => onMouseDown(e.targetTouches[0])}
             style={{
                 cursor: mousePressed === 2 ? 'grabbing' : 'pointer',
             }}
        >
            <div 
                ref={trackRef}
                className={'carousel-track'}
                onClick={onClick}
                style={{
                    transform: `translate3d(${currentTranslate}px, 0, 0)`,
                    transition: mousePressed === 2 ? '' : 'all 1000ms cubic-bezier(.46,.01,.51,.87)',
                }}
            >
                {showedElements.map((element: any) => (
                    <RenderComponent key={element._keyIndex} {...element}/>
                ))}
            </div>
        </div>
    );
}

export default InfinityCarousel;
