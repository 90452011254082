import React, {createRef, CSSProperties, useEffect, useState} from 'react';
import './AboutUs.scss';
import WeAre from "./WeAre/WeAre";
import agile from "./agile.svg";
import collaborative from "./collaborative.svg";
import committed from "./committed.svg";
import passionate from "./passionate.svg";
import professional from "./professional.svg";
import liquidFigure from "./liquid-figure-min.png";
import rightBg from "./right-bg.jpg";
import {usePageScrolling} from "../../hooks/usePageScrolling";
import InfinityCarouselStaticWidth from "../../helpers/InfinityCarouselStaticWidth/InfinityCarouselStaticWidth";

function AboutUs() {    
    const rootRef = createRef<HTMLDivElement>();
    const scroll = usePageScrolling();
    const [isShow, setIsShow] = useState(false);
    
    useEffect(() => {
        if(!isShow && ((rootRef.current?.getBoundingClientRect().top  || 0) +(window.innerHeight / 2)) < scroll) setIsShow(true);
    }, [scroll, rootRef.current]);
    
    const leftColumnItems = [
        {
            icon: committed,
            title: 'We are committed',
            text: 'A diverse team of highly skilled top-notch professionals - developers, analysts, and designers - we are all committed to excellence and to providing our customers with the very best services.',
            style: {
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 500ms',
            } as CSSProperties,
        },{
            icon: agile,
            title: 'We are agile',
            text: 'Experienced in developing custom-tailored complex solutions, we dynamically research technical and functional aspects as we provide practical solutions and gradually upgrade and improve them.',
            style: {
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 1500ms',
            } as CSSProperties,
        },{
            icon: passionate,
            title: 'We are passionate',
            text: 'You, our customers, and your ideas, are our inspiration. As we see it, we get to do this magic – bringing your ideas into being – and we love it! Let us handle your project, and we are fully confident that you\'ll love the results!',
            style: {
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 2500ms',
            } as CSSProperties,
        }
    ];
    const rightColumnItems = [
        {
            icon: professional,
            title: 'We are professional',
            text: 'Our experts are always up to date on the latest innovations in their fields, and draw on their ever-growing knowledge, wealth of technical expertise, business acumen and ingenuity to benefit your project.',
            style: {
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 3500ms',
            } as CSSProperties,
        },{
            icon: collaborative,
            title: 'We are collaborative',
            text: 'Our professionals can supplement your team and effectively become your new IT crew, working with you, and doing everything needed to lead your project to a successful conclusion.',
            style: {
                transition: 'all 1s cubic-bezier(0, 0, 1, 1) 4500ms',
            } as CSSProperties,
        }
    ];

    return (
        <>
            <img className={'AboutUs-background'} src={rightBg} alt={'animatedImg'} />
            <div ref={rootRef} className="AboutUs modal-bg">
                
                <div className="content">
                    <img className={'animated-image levitate'} src={liquidFigure} alt={'animatedImg'} />
                    <div className={'forTitle'}>
                        <div className={'titleKey'}>about us</div>
                        <div className={'title'}>Who We are</div>
                        <div className={'underTitleText'}>Commitment to excellence. Tahion.pro is committed to its customers' business goals and holds itself to the highest standards of professionalism, service and ultimately, excellence in results and customer satisfaction.</div>
                    </div>
                    <div className={'columns'}>
                        {window.innerWidth < 768 
                            ? false
                            : 
                                <>
                                    <div>
                                        {leftColumnItems.map((item) => (
                                            <WeAre key={item.title} isShow={isShow} icon={item.icon} title={item.title} text={item.text} style={item.style} />
                                        ))}
                                    </div>
                                    <div className={'right'}>
                                        {rightColumnItems.map((item) => (
                                            <WeAre key={item.title} isShow={isShow} icon={item.icon} title={item.title} text={item.text} style={item.style} />
                                        ))}
                                    </div>
                                </>
                        }
                        
                    </div>
                </div>
                { window.innerWidth < 768
                    ? <InfinityCarouselStaticWidth itemWidth={window.innerWidth < 450 ? 1500: 1700} itemRender={
                            () => (<>
                                {leftColumnItems.map((item) => (
                                    <WeAre key={item.title} isShow={true} icon={item.icon} title={item.title} text={item.text} style={item.style} />
                                ))}
                                {rightColumnItems.map((item) => (
                                    <WeAre key={item.title} isShow={true} icon={item.icon} title={item.title} text={item.text} style={item.style} />
                                ))}
                            </>)
                        } />
                    : false
                }
            </div>
        </>
    );
}

export default AboutUs;
