import React, {useState} from 'react';
import './Testimonials.scss';
import alexey_meirov from './alexey_meirov.jpg';
import gad_oron from './gad_oron.jpg';
import rafael_branch from './rafael_branch.jpg';
import Testimonial from "./Testimonial/Testimonial";
import Slider from "../../helpers/Slider/Slider";

function Testimonials() {
    const [width] = useState(window.innerWidth <= 1024 
        ? window.innerWidth <= 450 
            ? 305
            : 430
        : 584);
    
    const testimonialsList = [
        {
            firstLine: 'Alexey M., CTO',
            secondLine: 'CD-Log (Israel)',
            text: 'Tahion.pro team has extensive technical expertise, shows responsibility and initiative. Our cooperation with Tahion.pro is really very effective, we continue to develop our IT products together.',
            avatar: alexey_meirov,
        },
        {
            firstLine: 'Gad Oron, CEO',
            secondLine: 'Music Business (Israel)',
            text: 'We approached Dan Smirnoff of Tahion.pro who in a critical timeline learned our needs and created for us an easy-to-use "friendly tool" for the vast information, accessible on every mobile phone allowing tens of thousands of visitors to acquire, along with excellent audience management, excellent orientation and more by simple bar cord scan.',
            avatar: gad_oron,
        },
        {
            firstLine: 'Rafael Branch, CEO',
            secondLine: 'Gamers Outlet LLC (USA)',
            text: 'Gamers-Outlet LLC would like to thank Tahion.pro for a great website. The developers of the company approached the process of implementing all the work with special attention. The company operates at a high professional level, and quality of the work met all our expectations.',
            avatar: rafael_branch,
        },
    ];
    
    return (
        <div className="Testimonials modal-bg">
            <div className={'titleKey'}>testimonials</div>
            <Slider items={testimonialsList} itemRender={Testimonial} itemWidth={width} /> 
            <div id={'who-we-are'} />
        </div> 
    );
}

export default Testimonials;
