import React, {useEffect, useState} from 'react';
import './IndustriesModal.scss';
import Modal from "../../../../helpers/Modal/Modal";

interface IModalProps {
    isOpen: boolean,
    closeModal: Function,
    data: {
        id: number,
        icon: any,
        title: string,
        shortText: string,
        text: string,
    }
}

function IndustriesModal(props: IModalProps) {
    const [localOpen, setLocalOpen] = useState(props.isOpen);
    
    useEffect(() => {
        if(props.isOpen){
            setLocalOpen(true);
        }
    }, [props.isOpen])

    useEffect(() => {
        if(localOpen){
            document.body.classList.remove('modal-inactive');
            document.body.classList.add('modal-active');
        }
        else{
            const needAdd = document.body.classList.contains('modal-active');
            document.body.classList.remove('modal-active');
            if(needAdd) {
                document.body.classList.add('modal-inactive');
                setTimeout(() => {
                    props.closeModal();
                }, 500);
            }
        }
    }, [localOpen])
    
    const closeModal = () => {
        setLocalOpen(false);
    }
    
    if(!props.data) return <></>;
    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={closeModal}
        >
            <div className={'IndustriesModal'}>
                <svg className={'closeButton'} onClick={() => closeModal()} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.8262 30.8765L5.62657 7.67683C5.04447 7.09472 5.04721 6.14712 5.6327 5.56164C6.21819 4.97615 7.16579 4.9734 7.74789 5.55551L30.9475 28.7551C31.5296 29.3373 31.5269 30.2849 30.9414 30.8703C30.3559 31.4558 29.4083 31.4586 28.8262 30.8765Z" fill="#263238"/>
                    <path d="M30.9478 7.70797L7.74812 30.9076C7.16601 31.4897 6.21841 31.487 5.63293 30.9015C5.04744 30.316 5.04469 29.3684 5.62679 28.7863L28.8264 5.58665C29.4085 5.00454 30.3561 5.00729 30.9416 5.59278C31.5271 6.17827 31.5299 7.12587 30.9478 7.70797Z" fill="#263238"/>
                </svg>
                <div className={'content'}>
                    <div className={'header'}>
                        <img src={props.data.icon} alt={''} />
                        <div className={'right-logo-text'}>
                            {props.data.title}
                        </div>
                    </div>
                    <div className={'scroll-content'}>
                        {props.data.text}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default IndustriesModal;
