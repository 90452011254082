import React, {CSSProperties} from 'react';
import './WeAre.scss';

interface IWeAreProps {
    icon: any,
    title: string,
    text: string,
    isShow: boolean,
    style: CSSProperties
}

function WeAre(props: IWeAreProps) {
    const style = Object.assign(props.style, {opacity: props.isShow ? 1 : 0});
    return (
        <div className="WeAre" style={style}>
            <div className={'forTitle'}>
                <img src={props.icon} alt={props.title}/>
                <div>{props.title}</div>
            </div>
            <div className={'text'}>{props.text}</div>
        </div>
    );
}

export default WeAre;
